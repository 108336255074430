@import "../base/colors";
@import "../helpers/mixins";

.aktualnosc {
  display: flex;
  margin-bottom: 120px;
  .content {
    width: 65%;
    margin-right: 30px;
  }

  .meta {
    margin-bottom: 60px;
    .date {
      margin-right: 20px;
    }

    .icon-print {
      margin-right: 7px;
    }
  }

  .image {
    width: 35%;
    position: relative;

    .date {
      position: absolute;
      right: 0;
      top: 0;
      height: 80px;
      min-width: 80px;
      color: #fff;
      text-align: center;
      padding-top: 11px;
      background-color: $light-blue;
      .number {
        font-size: 2.14em;
        font-weight: bold;
        line-height: 1.1;
        padding: 0 10px;
      }
      .month {
        font-weight: bold;
        line-height: 1.1;
        font-size: 1.85em;
        padding: 0 10px;
      }
    }
  }
}

.inner-page-table-container {
  width: 100%;
  .inner-page-pagination {
    max-width: 300px;
    margin-top: 15px;
  }

  .logos {
    text-align: center;
    margin: 60px 0;
  }

  .bottom-container {
    text-align: center;
    background-color: #F1F1F1;
    padding: 20px 15px;

    span {
      font-size: 10px;
      display: block;
    }
  }
}

.dataTables_wrapper .dataTables_filter input {
  border-radius: 20px;
  border: 1px solid $light-grey;
  padding: 4px 10px;
  margin-left: 10px;
}

.dataTables_length {
  select {
    border: 1px solid $light-grey;
    padding: 0 10px;
    margin: 0 7px;
    border-radius: 10px;
  }
}

.dataTables_wrapper .dataTables_paginate .paginate_button {
  border-radius: 10px;
  border: 1px solid $light-grey;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.current {
  background: #000 !important;
  color: #fff !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.next {
}

.inner-page-table {
  margin-bottom: 60px;
  width: 100% !important;
  max-width: 100% !important;
  border: 0 !important;
  a {
    color: #000;
  }

  .response {
    flex: 1;
  }

  .head {
    background-color: $light-blue;

    .small, .name, .adress {
      background: none !important;
      border: 0 !important;
    }

    .name {
      width: 49% !important;
    }

    .adress {
      width: 27% !important;
    }

    .small {
      width: 140px !important;
    }

    a {
      color: #fff;
    }

    span {
      font-family: 'Lato', sans-serif;
    }
  }

  .body {
    border: 0 !important;
    .line:nth-child(even) {
      background-color: #F1F1F1;
    }

    .name {
      display: flex;
      align-items: center;
      span {
        font-weight: 600;
      }
    }
  }

  span.circle, span.num {
    display: inline-block;
    width: 33px;
    min-width: 33px;
    height: 33px;
    border-radius: 50%;
    line-height: 33px;
    text-align: center;
  }

  span.circle {
    border: 1px solid #fff;
    margin-right: 8px;
    .fa {
      color: #fff;
    }
  }

  span.num {
    background-color: #7bd5f9;
    margin-right: 8px;
    float: left;
  }

  .head, .line {
    display: flex;
    border-radius: 30px;
    padding: 18px !important;
    padding-left: 30px;
    align-items: center;
  }

  .name {
    width: 49%;
    span:not(.num) {
      line-height: 1.1;
    }

    span.circle {
      line-height: 33px;
    }
  }

  .read {
    display: inline-block;
    border-radius: 30px;
    border: 1px solid $light-blue;
    text-align: center;
    height: 32px;
    line-height: 32px;
    min-width: 130px;

    &:hover {
      background-color: $light-blue;
      color: #fff;
    }
  }

  .adress {
    width: 27%;
  }

  .small {
    width: 140px;
    text-align: center;
    word-break: break-all;
  }

  .name, .adress, .small {
    padding: 0 10px 0 0 !important;
  }

  .adress, .small {
    font-family: 'Lato', sans-serif;
  }

  tbody{
  	.name{
  		span:not(.num){
  			float: left;
  			width: calc(100% - 43px);
  		}
  	}
  }

}

.files-download {
  max-width: 996px;
  margin-bottom: 60px;
  .line:nth-child(odd) {
    background-color: #E1E1E1;
  }
  .line {
    display: flex;
    padding: 15px 15px 8px;
    align-items: center;
    span {
      flex: 1;
      display: inline-block;
      padding-right: 10px;
    }

    .icon-download {
      left: 10px;
      top: 6px;
      position: absolute;
    }

    .download {
      position: relative;
      width: 200px;
      min-width: 200px;
      height: 50px;
      line-height: 50px;
      display: inline-block;
      border-radius: 30px;
      color: #707070;
      border: 1px solid;
      text-align: center;

      &:hover {
        background-color: $blue;
        color: #fff;
        border-color: $blue;
        .icon-download {
          background-image: url('../img/icon-download-white.png');
        }
      }
    }
  }
}

.spatial-development-block {
  display: flex;

  .content {
    width: 60%;
    margin-right: 40px;
    .line {
      display: flex;
      margin-bottom: 20px;
      .middle-title {
        max-width: 75%;
      }
      a {
        margin-left: auto;
        margin-right: 8px;

        .fa-file-archive-o{
          font-size: 6.7em;
          color: #000;
        }
      }
    }

    .meta {
      .date {
        margin-right: 20px;
      }

      .icon-print {
        margin-right: 8px;
      }

      margin-bottom: 40px;
    }

    table {

      tr {

        td:first-child {
          padding-right: 10px;
        }
        td:last-child {
          font-weight: bold;
        }
      }
    }
  }

  .image {
    width: 40%;
  }
}

.percent-block {
  display: flex;
  align-items: center;
  margin-left: 8%;

  p {
    max-width: 415px;
  }

  .num {
    font-weight: bold;
    font-size: 8.57em;
    position: relative;
    color: $light-blue;
    margin-right: 20px;
    &:before {
      position: absolute;
      content: '';
      width: 123px;
      height: 114px;
      background-size: contain;
      background-repeat: no-repeat;
      background-image: url('../img/inner/percent.png');
      right: -69px;
      top: -25px;
    }
  }
}

.percent-block.reverse {
  margin-right: 8%;
  margin-left: 0;
  flex-direction: row-reverse;
  .num {
    margin-right: 0;
  }
}

.big-blue-title {
  font-size: 3.35em;
  margin-bottom: 50px;
  font-weight: 900;
  line-height: 1.1;
  color: $light-blue;
}

.subzone {
  max-width: 1080px;
  margin-bottom: 60px;
  .line {
    display: flex;
    margin-bottom: 6px;

    .grey-block, .blue-block {
      min-height: 86px;
      display: flex;
      align-items: center;
      height: auto;
      line-height: 1.2;
      padding: 0 15px;
    }

    .grey-block {
      margin-right: 10px;
      width: 60%;
      text-align: left;
    }

    .blue-block {
      width: 40%;
      font-size: 2.5em;
      justify-content: center;
    }
  }
}

.blue-place {
  display: flex;
  margin-bottom: 120px;
  .content {
    flex: 1;
    margin-right: 30px;
  }

  .middle-title {
    margin-bottom: 30px;
  }

  .meta {
    margin-bottom: 40px;

    .date {
      margin-right: 20px;
    }
    .icon-print {
      margin-right: 8px;
    }
  }

  p.main-paragraph {
    margin-bottom: 30px;
  }

  .adress-block {
    margin-top: 30px;
    a.read-all {
      background-color: $light-blue;
      border-color: $light-blue;
      font-weight: bold;
      text-align: left;
      padding-left: 15px;
      font-size: 2.14em;
      margin-bottom: 15px;
      color: #fff;
      &:hover {
        background-color: #fff;
        color: $light-blue;
      }
    }

    a {
      font-weight: bold;
      color: $light-blue;
    }
  }

  .thumbnail {
    width: 536px;
    height: 517px;
    margin-bottom: 4px;
  }
}

.blue-place.no-thumbnails {
  margin-left: 8%;
  .images {
    display: none;
  }
}

.blue-place.reverse {
  flex-direction: row-reverse;
  .content {
    margin-right: 0;
    margin-left: 30px;
  }
}

/**********************************************************************/
@media screen and (max-width: 1640px) {
  .blue-place .thumbnail {
    width: 320px;
    height: 300px;
  }

  .spatial-development-block .content .line a .fa-file-archive-o{
    font-size: 4em;
  }
}

@media screen and (max-width: 991px) {
  .subzone .line .blue-block {
    font-size: 2em;
  }

  .blue-place.no-thumbnails {
    margin-left: 0;
  }
}

@media screen and (max-width: 767px) {

  .blue-place {
    flex-wrap: wrap;
    flex-direction: row !important;
    .content {
      margin-bottom: 30px;
      margin-right: 0 !important;
      margin-left: 0 !important;
    }
  }

  .subzone .line {
    flex-wrap: wrap;
    .blue-block, .grey-block {
      width: 100%;
      margin-right: 0;
      margin-bottom: 3px;
    }
  }

  .aktualnosc {
    flex-wrap: wrap;
    flex-direction: column-reverse;
    .content {
      width: 100%;
      margin: 0;
    }

    .image {
      width: 100%;
      margin-bottom: 30px;
    }
  }

  .inner-page-table .head {
    flex-wrap: wrap;
  }

  .inner-page-table .line {
    flex-wrap: wrap;

    .name, .adress {
      width: 100%;
      margin-bottom: 10px !important;
      padding: 0 !important;
    }

    .response {
    }

    .small {
      width: 50%;
    }

  }

  .files-download .line {
    flex-wrap: wrap;
    span {
      flex: 1 1 100%;
      margin-right: 0;
      margin-bottom: 20px;
    }

    .download {
      margin: auto;
      margin-bottom: 10px;
    }
  }

  .spatial-development-block {
    flex-wrap: wrap;
    .content, .image {
      width: 100%;
    }

    .content {
      margin-bottom: 30px;
    }
  }
}

@media screen and (max-width: 576px) {

  .blue-place .thumbnail {
    width: 290px;
    height: 270px;
  }

  .spatial-development-block .content {
    .line {
      flex-wrap: wrap;
      .middle-title {
        max-width: 100%;
      }

      a {
        margin-top: 15px;
        margin-left: 0;
      }
    }

    table {
      font-size: .7em;
    }
  }

  .percent-block {
    margin-left: 0 !important;
    margin-right: 0 !important;
    flex-wrap: wrap;
    margin-bottom: 20px;
    .num {
      font-size: 3em;
      &:before {

        width: 50px;
        height: 50px;
        right: -27px;
        top: -12px;
      }
    }
  }

  .percent-block.reverse {
    flex-direction: row;
    .num {
      margin-right: 20px;
    }
  }

  .big-blue-title {
    font-size: 2em;
  }
}