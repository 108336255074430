@import "../base/colors";
@import "../helpers/mixins";

#home-greatest-left,
#home-greatest-right {
  background: none !important;
  border: 0 !important;

  /**/
}

.calendar-form-container {
  background-color: rgba(225, 225, 225, .46);
  padding-top: 29px;
  padding-bottom: 39px;

  .title {
    font-weight: 500;
    font-size: 1.28em;
    margin-bottom: 11px;
    margin-left: -15px;
  }

  form {

    input, select {
      border: none;
      border-radius: 20px;
      height: 42px;
      padding: 0 10px;
      margin-right: 17px;
      margin-bottom: 18px;
    }

    input[type=text] {
      width: 430px;
    }

    select {
      width: 260px;
    }

    input[type=text].datepicker {
      width: 163px;
    }

    input[type=submit] {
      width: 250px;
      background-color: $blue;
      color: #fff;
      @include transition(all, .4s);
      margin-right: 0;
      border: 2px solid $blue;
      &:hover {
        color: $blue;
        background: #fff;
      }
    }

  }
}

/**/
.calendar-years-months {
  position: relative;
  padding-top: 20px;
  overflow: hidden;
  margin-bottom: 90px;
  &:before {
    content: '';
    width: 100%;
    position: absolute;
    height: 2px;
    background-color: #fff;
    bottom: 0px;
    left: 0;
    display: block;
    z-index: 1;
  }

  .calendar-moth-wrap {
    max-width: calc(100% - 60px);
  }

  .arrow {
    font-size: 3em;
    color: $aqua;
    position: relative;
    a {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  .arrow-left {
    margin-right: 15px;
  }

  .arrow-right {
    margin-left: 15px;
  }

  .row {
    flex-wrap: nowrap;
    align-items: baseline;
    position: relative;
  }

  .month {
    font-size: 1.35em;
    color: #fff;
    margin: 0 10px;
    padding: 0 10px;
    cursor: pointer;
  }

  .month.active {
    color: $yellow;
    position: relative;
    &:before {
      content: '';
      display: block;
      height: 5px;
      width: 100%;
      position: absolute;
      left: 0;
      bottom: -12px;
      background-color: $yellow;
      z-index: 2;
    }
  }

  .year {
    font-size: 3.28em;
    color: $aqua;
    font-weight: 500;
    margin: 0 15px;
  }
}

.calendar-days {
  margin-bottom: 90px;
  a {
    color: #fff;
  }

  > .row {
    align-items: baseline;
    justify-content: space-between;
  }

  .arrow {
    font-size: 4.2em;
    color: $aqua;
    position: relative;
    a {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  .day {
    cursor: pointer;
    line-height: 1.2;
    color: #fff;
    text-align: center;
    margin: 0 28px;
  }

  .days-wraper {
    overflow: hidden;
    max-width: calc(100% - 80px);
    .row {
      flex-wrap: nowrap;
      position: relative;
    }
  }

  .day.active {
    color: $yellow;
  }

  .number {
    font-size: 3.52em;
    font-weight: 500;
  }

  .weekday {
    font-size: 1.42em;
  }
}

.calendar-events {
  > .row {
    //justify-content: center;
  }

  .calendar-event {
    position: relative;
    width: 24%;
    margin: 0 8px 16px;

    a.link {
      color: #fff;
    }

    .time, .date {
      position: absolute;
      top: 14px;
      font-weight: 500;

      text-shadow: #000 1px 1px 1px,
      #000 -1px -1px 1px,
      #000 -1px 1px 1px,
      #000 1px -1px 1px;
    }

    .date {
      left: 28px;
      font-size: 1.85em;
      line-height: 1.1;
      padding: 0 7px;
      hr {
        margin: 5px -5px;
      }
    }

    .time {
      right: 18px;
      font-size: 3.28em;
    }

    .thumbnail {
      height: 200px;
      width: 100%;
      overflow: hidden;
      img {
        width: 100%;
      }
    }

    .title {
      line-height: 1.1;
      font-weight: 600;
      text-transform: uppercase;
      color: #fff;
      position: absolute;
      top: -45px;
      margin: 0;
      font-size: 2.57em;
      span {
        display: block;
        opacity: .6;
      }
    }

    .text {
      font-size: 1.42em;
      color: #fff;
      line-height: 1.2;
    }

    .content {
      padding: 45px 60px 15px 35px;
      position: relative;
      min-height: 170px;
      &:before {

        content: '';
        position: absolute;
        left: 0;
        top: -25px;
        width: 85%;
        height: 50px;

      }
    }
  }

  .calendar-event.blue {
    .content {
      background-color: $light-blue;
      &:before {
        background-color: $light-blue;
      }
    }

    hr {
      border: 1px solid $light-blue;
    }
  }

  .calendar-event.yellow {
    .content {
      background-color: $yellow;
      &:before {
        background-color: $yellow;
      }
    }

    hr {
      border: 1px solid $yellow;
    }
  }

  .calendar-event.purple {
    .content {
      background-color: $purple;
      &:before {
        background-color: $purple;
      }
    }

    hr {
      border: 1px solid $purple;
    }
  }

}

.calendar-footer {
  margin-top: 300px;
  background-color: hsla(48, 92%, 58%, 0.9);
  padding: 65px 15px;

  .row {
    align-items: center;
    justify-content: space-evenly;
  }

  a {
    color: #fff;
  }

  .title {
    color: #000;
    font-size: 1.85em;
    font-weight: 600;
  }

  &-block {
    text-align: center;
    img {
      margin-bottom: 20px;
    }
  }
}

//**********************************************************************/

@media screen and (max-width: 1630px) {
  .calendar-events {
    .calendar-event {
      width: 40%;
    }

    .row {
      justify-content: center;
    }
  }
}

@media screen and (max-width: 1440px) {
  .calendar-footer {
    margin-top: 150px;
  }

  .calendar-footer .title {
    font-size: 1.5em;
  }

  .calendar-days .number {
    font-size: 2.1em;
  }

  .calendar-years-months {
    margin-bottom: 60px;
  }

  .calendar-years-months {
    .month {
      font-size: 1.1em;
    }

    .years {
      font-size: 2.28em;
    }
  }
}

@media screen and (max-width: 1290px) {
  .calendar-events .calendar-event {
    width: 30%;
    .time {
      font-size: 2.28em;
    }

    .text {
      font-size: 1.1em;
    }

  }
}

@media screen and (max-width: 1199px) {

  .calendar-events .calendar-event {
    .thumbnail {
      height: 150px;
    }
    .time {
      font-size: 2px;
    }
    .date {
      font-size: 1.5em;
    }

    .title {
      font-size: 2em;
    }
  }
}

@media screen and (max-width: 991px) {
  .calendar-events .calendar-event {
    .title {
      font-size: 1.6em;
    }
    .thumbnail {
      height: 130px;
    }

    .content {
      padding-top: 25px;
      min-height: 140px;
    }
  }
}

@media screen and (max-width: 767px) {
  .calendar-events .calendar-event {
    width: 300px;
    //width: calc(100% - 25px);
    margin-left: 0;
    margin-right: 0;
  }

  .calendar-years-months, .calendar-days, .calendar-form-container {
    .row {
      margin: 0;
    }
  }

  .calendar-form-container {
    .title {
      margin-left: 0;
    }
  }

  .calendar-footer-block {
    text-align: center;
    width: 100%;
    padding: 0 15px;
    margin: 10px 0;
  }

  .calendar-days .day {
    margin: 0 10px;
    font-size: .5em;
  }

  .calendar-days > .row {
    align-items: center;
  }
}

@media screen and (max-width: 575px) {
  .calendar-form-container form input, .calendar-form-container form select {
    margin-right: 0;
    width: 100%;
  }

  .calendar-footer {
    margin-top: 20px;
    padding: 30px 15px;

    .title {
      font-size: 1.86em;
    }
  }

  .calendar-events .calendar-event .title {
    font-size: 2.28em;
  }

  .calendar-form-container form {
    input[type=text].datepicker {
      width: calc(50% - 7.5px);
    }

    #Contents_EventSearchModel_from {
      margin-right: 15px;
    }

    input.datepicker:last-child {
      margin-right: 15px;
    }
  }

  .calendar-form-container form input[type=submit] {
    width: 100%;
    margin-top: 20px;
  }

  /**/
  .calendar-days {
    .day {
      margin: 0 15px;
      font-size: 1em;
    }
    .number {
      font-size: 3.33em;
    }
  }
}

@media screen and (max-width: 425px) {
  .calendar-events .calendar-event .title {
    font-size: 2.28em;
  }

  .calendar-events .calendar-event .time {
    font-size: 2em;
    right: 10px;
  }

  .calendar-events .calendar-event .date {
    font-size: 1.5em;
    left: 10px;
  }

  .calendar-events .calendar-event .text {
    font-size: 1.42em;
  }
}

@media screen and (max-width: 410px) {
  .calendar-days {
    .day {
      margin: 0 10px;
      width: calc(100vw - 110px);
    }
    .number {
      font-size: 2.3em;
    }
  }
}