
.newsletter {
  width: 600px;
  font-size: 14px;
  &-head {
    position: relative;
    background-color: $light-blue;
    color: #fff;
    line-height: 30px;
    display: flex;
    height: 138px;

    align-items: center;

    padding-left: 40px;
    padding-right: 30px;

    &-left {
      font-weight: 800;
      font-size: 2.57em;
      font-family: 'Lato', sans-serif;
    }

    &-right {
      margin-left: auto;
      font-size: 1.85em;

    }

    &-logo {
      background-image: url('newsletter-logo.png');
      width: 101px;
      height: 170px;
      position: absolute;
      z-index: 10;
      right: 50%;
      margin-right: -50.5px;
      top: 0;
    }
  }

  &-after-head {
    font-family: 'Lato', sans-serif;
    padding: 30px 40px;
    background-color: $light-grey;
    line-height: 36px;
    font-size: 2.14em;
    font-weight: 800;
  }
}

.newsletter-divider {
  margin: 0 40px 23px 40px;
  height: 1px;
  background-color: $light-grey;
}

.newsletter-news {
  width: 294px;
  margin-bottom: 70px;

  display: flex;
  flex-direction: column;

  &-title {
    color: #fff;
    font-weight: 800;
    font-size: 2.14em;
    font-family: 'Lato', sans-serif;
    line-height: 1.1;

    margin: auto 30px 17px;
  }

  &-thumbnail {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 257px;
    margin-bottom: 28px;
    position: relative;
  }

  &-text {
    font-size: 1.14em;
    font-family: 'Lato', sans-serif;
    padding: 0 30px;
  }

  .read-all {
    font-size: 1.07em;
    font-family: 'Lato', sans-serif;
    width: 245px;
    height: 49px;
    line-height: 45px;

    margin-left: 30px;
    margin-right: 30px;

    margin-top: auto;
  }
}

.newsletter-big-news {
  width: 100%;
  margin-bottom: 39px;
  .newsletter-news-thumbnail {
    width: calc(100% - 80px);
    height: 308px;
    margin: 0 40px 28px;
  }

  .newsletter-news-text {
    padding: 0 40px;
  }

  .read-all {
    margin-left: 40px;
    margin-right: 40px;
  }
}

.newsletter-small-news-container {
  display: flex;

  .newsletter-news:first-child {
    margin-right: 12px;
  }
}

.newsletter-footer {
  min-height: 148px;
  background-color: $footer-bg;
  padding: 17px 40px;

  .row {
    margin: 0;
    align-items: center;
  }
}

.newsletter-follow-us {
  span {
    font-weight: 400;
    color: #fff;
    font-size: 1em;
    margin-right: 26px;
  }
}

.newsletter-follow-us {
  display: flex;
}

.follow-us-icon {
  display: inline-block;
  width: 23px;
  height: 23px;
  border-radius: 5px;
  border: 1px solid #fff;
  color: #fff;
  line-height: 21px;
  text-align: center;
  margin-right: 4px;

  em, i {
    @include transition(all, .4s);
    color: #fff;
  }

  &:hover {
    color: $blue;
    border-color: $blue;
    em, i {
      color: $blue;
    }
  }
}

.newsletter-unsubscribe {
  margin-left: auto;
  font-family: 'Lato', sans-serif;
  color: #fff;
  width: 261px;
  height: 44px;
  background-color: $blue;
  display: inline-block;
  text-align: center;
  line-height: 44px;
  font-weight: bold;
  font-size: 1.14em;

  &:hover {
    color: #fff;
    background-color: $dark-blue;
  }
}