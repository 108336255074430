@import "../base/colors";
@import "../helpers/mixins";

.header-search-container {
  position: absolute;
  height: 43px;
  top: -50px;
  right: 147px;
  z-index: 20;
  @include transition(all, .5s);
  form {
    position: relative;
    width: 550px;
    background-color: $aqua;
    input {
      background-color: inherit;
      height: 43px;
      padding: 0 10px;
      border-radius: 10px;
      width: 530px;
      border: 0;
      color: #fff;
    }

    input::placeholder {
      color: #fff;
    }

    .close {
      color: #000;
      position: absolute;
      right: 5px;
      top: 5px;
    }
  }
}

#header-search-form-mobile {
  position: absolute;
  background-color: #fff;
  border: 1px solid #000;
  @include transition(all, .6s);
  height: 40px;
  display: flex;
  left: 10px;
  top: -100px;
  input {
    border: 0;
    padding: 0 10px;
    height: 38px;
    width: calc(100% - 38px);
  }

  button {
    margin-left: auto;
    border: 0;
    width: 38px;
    height: 100%;
    background-color: $light-blue;
    color: #fff;
    text-align: center;
    line-height: 38px;
    margin-left: auto;

    i, em {
      color: #fff;
    }
  }

  .close-form {
    font-size: 1em;
    position: absolute;
    top: 0;
    left: auto;
    right: -17px;
    color: #fff;
  }
}

#header-search-form-mobile.visible {
  top: 46px;
}

.header-search-container.visible {
  top: 2px;
}

.inner-header-container {
  height: 550px;
  position: relative;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  background-size: cover;

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .2);
    pointer-events: none;

  }

  .title {
    position: absolute;
    bottom: 32%;
    line-height: 1;
    width: 100%;
    padding: 0 15px;
    text-align: center;
    font-size: 5.74em;
    color: #fff;
    font-weight: 500;
    text-shadow: 1px 2px #000;
  }
}

.index-header-container {
  height: 900px;
  position: relative;

  .slide {
    width: 100%;
    height: 100%;
    min-height: 100%;
    &:before { /**/
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, .2);
      pointer-events: none;
      z-index: 5;
    }
  }

  .index-slider {
    height: 100%;
  }

  .bx-pager {
    position: absolute;
    bottom: 115px;
    display: flex;
    align-items: center;
    justify-content: center;

    a {
      background-color: #fff !important;
    }

    a:hover {
      background-color: #cccccc !important;
    }

    a.active {
      display: inline-block;
      border: 2px solid #fff;
      width: 15px;
      height: 15px;
      border-radius: 50%;
      background-color: inherit !important;
    }
  }

  .bx-wrapper {
    border: 0 !important;
    height: 100% !important;
    .bx-viewport {
      height: 100% !important;
    }
  }
}

.header {
  z-index: 100;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  height: 116px;
  background-color: rgba(0, 0, 0, .3);
  padding-top: 45px;
  a {
    color: #fff;
  }

  .row {
    position: relative;
  }

  .home-link {
    font-size: 1.85em;
    line-height: 1.2;
    margin-right: 30px;
  }

  .main-menu {
    font-size: 1.42em;
    position: relative;
    padding: 0;
    list-style: none;
    ul {
      padding: 0;
      list-style: none;

    }
    > li {
      display: inline-block;
      margin: 0 10px;
      position: relative;
      a {
        padding: 10px 20px 10px;
        border: 1px solid rgba(0, 0, 0, 0);
        display: inline-block;
      }
    }

    > li:hover {
      > a {
        border: 1px solid $aqua;
        color: $aqua;
        // font-weight: 500;
      }

      ul {
        display: block;
        li {
          a {
            color: #000;
          }
        }
      }
    }

    &-submenu {
      position: absolute;
      left: 0;
      top: 45px;
      display: none;
      li {
        margin: 0;
        min-width: 350px;
        background: rgba(255, 255, 255, .67);
        border: 1px solid rgba(255, 255, 255, .5);
        margin-bottom: 3px;

        a {
          padding-top: 5px;
          padding-bottom: 10px;
          padding-left: 35px;
          width: 100%;
          color: #000;
        }

        &:hover {
          background-color: #fff;
          border: 1px solid #fff;
        }
      }
    }

    > li:after {
      content: '';
      border-radius: 50%;
      display: block;
      position: absolute;
      width: 4px;
      height: 4px;
      background-color: #fff;
      top: 50%;
      margin-top: -2px;
      right: -12px;
    }

    li:first-child {
      margin-left: 0;

    }

    li.after-img {
      &:before {
        content: '';
        display: block;
        position: absolute;
        width: 4px;
        height: 4px;
        background-color: #fff;
        top: 50%;
        margin-top: -2px;
        left: -12px;
      }
    }

    li:last-child {
      margin-right: 0;
      &:after {
        display: none;
      }
    }

    &-img {
      width: 163px;
      padding: 0 !important;
      position: relative;

      a {
        padding: 0 !important;
      }

      &:after {
        display: none !important;
      }
      .wrap {
        position: absolute;
        top: -86px;
        left: 0;
        width: 100%;
        overflow: hidden;

        img {
          max-width: 100%;
        }
      }
    }
  }

  .otherlang-menu {
    .main-menu {
      font-size: 1.2em;
      > li a {
        padding: 10px 8px;
      }
    }
  }
}

.bip-icon {
  position: absolute;
  right: 0;
  top: 5px;
}

.header-right-top {
  position: absolute;
  right: 0;
  height: 36px;
  min-width: 320px;
  top: -38px;
  display: flex;
  background-color: $yellow;

  div {
    height: 100%;
    line-height: 36px;
  }

  .search {
    cursor: pointer;
    width: 36px;
    text-align: center;
    background-color: $light-blue;

    .fa {
      color: #fff;
    }

    a {
      color: #fff;
    }
  }

  .icon-contrast {
    width: 36px;
    height: 36px;
    background-color: #000;
    background-image: url('../img/header/icon-contrast.png');
    background-repeat: no-repeat;
    background-position: 50% 50%;
    cursor: pointer;
  }

  .font-size {
    width: 56px;
    border-right: 1px solid #C8A826;

    .wrap {
      margin: auto;
      width: 35px;
    }

    span {
      cursor: pointer;
    }

    .small {
      font-size: 7px;
    }

    .normall {
      font-size: 10px;
    }

    .big {
      font-size: 13px;
    }
  }

  .languages {
    font-size: 13px;
    margin-left: auto;
    padding: 0 8px;
    .lang.active {
      font-weight: bold;
    }

    .lang {
      color: #000;
      cursor: pointer;
      padding: 0 7px;
      font-weight: 300;
      position: relative;
      &:after {
        border-radius: 50%;
        content: '';
        display: block;
        position: absolute;
        width: 4px;
        height: 4px;
        background-color: #000;
        top: 50%;
        margin-top: -2px;
        right: -4px;
      }

      &:hover {
        color: $blue;
      }
    }

    .lang:last-child {
      &:after {
        display: none !important;
      }
    }
  }
}

@media screen and (max-width: 1630px) {

  .index-header-container {
    height: 710px;
  }

  .header {
    .main-menu {
      font-size: 1.2em;
      &-img {
        width: 130px;
        .wrap {
          top: -75px;
        }
      }

      > li {
        margin: 0 6px;
        &:after {
          right: -9px;
        }
        a {
          padding: 10px;
        }
      }

      li.after-img {
        &:before {
          left: -9px;
        }
      }
    }
  }
}

.mobile-menu-container {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 100%;
  top: 0;
  z-index: 100;
  background-color: #004789;
  overflow-y: auto;

  padding-top: 60px;
  @include transition(all, .4s);

  .header-right-top {
    display: flex;
    max-width: 380px;
    align-items: center;
    justify-content: center;
    position: static;
    margin-top: 8px;
    margin-bottom: 30px;
    div {
      height: 36px;
    }

    #mobile-toggle-search {
      cursor: pointer;
      width: 36px;
      text-align: center;
      background-color: $blue;
      i, em {
        color: #fff;
      }
    }

    .bip-icon {
      display: block !important;
      position: static;

      img {
        height: 32px;
      }
    }

    .languages {
      margin-left: 0;
    }
  }

  .header-right-top {
    .search, .icon-contrast, .font-size {
    }
  }

  .main-menu {
    font-size: 1.42em;

    li.has-child {

      position: relative;
      > a {
        display: flex;
        padding-right: 0;
        align-items: center;
        border: 1px solid transparent !important;
      }

      .fa, em, i {
        margin-left: auto;
        color: #fff;
        cursor: pointer;

        width: 50px;
        height: 30px;
        text-align: center;
        line-height: 30px;
      }

      .main-menu-submenu {
        margin: 5px -20px 0;
      }
    }

    li.has-child.active {
      padding-bottom: 0;
      > a {
        border: 1px solid rgba(2, 161, 231, .5) !important;
        color: rgba(2, 161, 231, .5) !important;
      }

      em {
        color: rgba(2, 161, 231, .5);
      }
    }

    li {
      padding: 5px 20px;
      display: block;
      max-width: 450px;
      min-width: 450px;
      background: rgba(2, 161, 231, .5);
      box-shadow: 0 0 6px rgba(0, 0, 0, .5);
      margin-bottom: 1px;
      margin-left: 0;
      &:after {
        display: none !important;
      }

      a {
        display: flex;
        border: 0;
        padding: 8px 10px;
      }

      &:hover {
        a {
          border: 0;
        }
      }
    }

    > li {
      a + ul {

      }
    }

    .main-menu-submenu {
      position: static;

      li {
        background-color: rgba(255, 255, 255, .3);

        &:hover {
          background-color: rgba(255, 255, 255, 1);
        }
      }
    }
  }

  .close {
    font-size: 2em;
    position: absolute;
    top: 10px;
    left: 10px;
    color: #fff;
  }

  &.visible {
    left: 0;
  }
}

/************************************************************/

@media screen and (max-width: 1640px) {
  .inner-header-container .title {
    bottom: 33%;
    font-size: 4em;
  }

  .header .main-menu > li {
    font-size: 0.9em;
  }

  .header.otherlang {
    height: 160px;
    .otherlang-menu .main-menu {
      font-size: 1.1em;
    }

  }
}

@media screen and (max-width: 1199px) {

  .inner-header-container .title {
    bottom: 33%;
    font-size: 3em;
  }

  .header {
    height: 165px;
    .home-link {
      position: relative;
      top: -5px;
    }
  }

  .index-header-container {
    height: 550px;
    max-height: 100vh;
    min-height: 600px;
  }

  .index-header-container .bx-wrapper {
    margin: 0;
  }

  .header .main-menu > li {
    font-size: 0.85em;
  }

  .header.otherlang .otherlang-menu .main-menu {
    font-size: 0.88em;
  }
}

@media screen and (max-width: 991px) {
  .header {
    height: 100px;
    padding-top: 25px;
    background: none;

    .mobile-menu-toggler {
      display: block;
      position: absolute;
      right: 15px;
      top: 0;
      color: #fff;
      font-size: 2em;
      cursor: pointer;
    }
  }

  .index-header-container .bx-pager {
    bottom: 90px;
  }

  .header .main-menu,
  .header-right-top, .bip-icon {
    display: none;
  }

  .header .main-menu.mobile-menu, .mobile-menu-container {
    display: block;
  }
}

@media screen and (max-width: 767px) {
  .header .home-link {
    left: 10px;
  }

  .inner-header-container {
    height: auto;
    min-height: calc(300px + 72 * 3px);
  }

  .inner-header-container .title {
    bottom: auto;
    top: 45%;
  }

  .index-header-container .bx-pager {
    bottom: 177px;
  }
  /**/
  .index-header-container {
    max-height: 100vh;
    height: calc((100vw * 0.56));
    min-height: calc(300px + 55 * 3px);
  }

}

@media screen and (max-width: 575px) {

  .inner-header-container {
    height: 100vh;
    min-height: 100vh;
    display: flex;
    flex-direction: column;

    padding-top: calc(100vh - 72 * 3px);
  }

  .index-header-container .bx-pager {
    display: none;
  }

  .index-header-container {
    max-height: 100vh;
    height: 100vh;
    min-height: 100vh;
    position: relative;
    display: flex;
    flex-direction: column;
    .home-link {
      display: block;
      position: absolute;
      bottom: 250px;
      left: 30px;
      z-index: 20;
      color: #fff;
      font-size: 61px;
      line-height: 1;
    }
  }

  .index-header-container .bx-wrapper {
    height: calc(100vh - 72 * 3px) !important;
  }

  .inner-header-container .title {
    bottom: 28%;
    font-size: 2em;
  }

  .home-link-mobile {
    display: block;
    background-size: contain;
    background-repeat: no-repeat;
    width: calc(100vw * 0.35);
    height: calc(100vh * 0.318);
    margin-left: 30px;
    background-image: url('mobile-logo.png');
  }

  .header {
    padding-top: 0;

    .home-link {
      display: none;
    }

    .row {
      position: static;
    }

    .mobile-menu-toggler {
      position: static;
      margin-left: auto;

      width: 114px;
      height: 98px;
      text-align: center;
      line-height: 98px;
      background: rgba(0, 0, 0, .4);
    }
  }
}

@media screen and (max-width: 455px) {
  .mobile-menu-container .main-menu li {
    min-width: 100%;
  }
}

@media screen and (max-width: 375px) {
  .mobile-menu-container .main-menu li {
    max-width: 250px;
  }

  .index-header-container .home-link {
    left: 15px;
    font-size: 30px;
    bottom: 220px;
  }

  .home-link-mobile {
    margin-left: 15px;
  }

  .header-right-top .languages {
    font-size: 10px;
  }

  .mobile-menu-container .header-right-top {
    .languages {
      padding: 0 5px;
    }
  }
}