
.middle-title-and-link {
  .read-all {
    margin-top: 0;
    color: $light-blue;
    background-color: #fff;
    border: 1px solid $light-blue;
    &:hover {
      background-color: $light-blue;
      color: #fff;

    }
  }

  .row {
    align-items: center;
  }

  .middle-title {
    max-width: 60%;
    margin-right: 40px;
  }
}

.yellow-rada-block {
  display: flex;

  .content {
    flex: 1;
    margin-right: 30px;
  }

  .thumbnail {
    width: 536px;
    height: 517px;
  }

  .middle-title {
    max-width: 70%;
  }

  .middle-title-and-img {
    img {
      margin-right: auto;
    }
  }

  a {
    display: block;
  }

  .read-all {
    color: #fff;
    font-size: 2.14em;
    font-weight: bold;
    padding: 5px 10px;
    min-width: 250px;
    max-width: 290px;
    line-height: 1.1;
    width: auto;
    margin-bottom: 10px;
  }

  a {
    color: #000;
  }
}

.yellow-files-download {
  margin-bottom: 60px;
  .line {
    display: flex;
    align-items: center;
    margin-bottom: 4px;
    padding-left: 20px;
    min-height: 86px;
    background-color: $light-grey;
  }

  span {
    padding-right: 10px;
  }

  a.download {
    margin-left: auto;
    margin-right: 20px;
    position: relative;
    width: 200px;
    min-width: 200px;
    height: 50px;
    line-height: 50px;
    display: inline-block;
    border-radius: 30px;
    color: #fff;
    background-color: $blue;
    border: 1px solid;
    text-align: center;

    .custom-icon {
      background-image: url('../img/icon-download-white.png');
      position: absolute;
      left: 10px;
      top: 6px;
    }
  }
}

.inner-page-table-yellow {
  .head {
    background-color: $yellow;
    color: #fff;
  }

  span.num {
    background-color: #fdf0c0;
  }
}

.yellow-gallery {

  .row {
    justify-content: center;
  }

  .thumbnail {
    margin: 5px;
  }

  .small-thumb {
    width: 359px;
    height: 239px;
  }

  .big-thumb {
    width: 599px;
    height: 423px;
  }

  .high-thumb {
    width: 239px;
    height: 423px;
  }
}

/****************************************************************************************/
@media screen and (max-width: 1640px) {
  .yellow-rada-block .thumbnail {
    width: 356px;
    height: 317px;
  }

  .yellow-gallery {
    .small-thumb {
      width: 288px;
      height: 220px;
    }

    .high-thumb {
      width: 191px;
      height: 360px;
    }
    .big-thumb {
      width: 485px;
      height: 360px;
    }
  }
}

.under-gallery-links {
  a {
    color: $light-blue;
    font-size: 1.42em;
    font-weight: 600;
    margin-right: 15px;
    margin-bottom: 4px;
  }
}

.yellow-search-up, .yellow-search-under {
  min-height: 61px;
  border: 1px solid #A9A9A9;
  margin-bottom: 50px;

  .row {
    align-items: center;
    margin-bottom: 0;
    div {
      margin-left: auto;
      padding: 0 15px;
    }
  }

  a {
    display: inline-block;
    height: 61px;
    min-width: 138px;
    padding: 0 10px;
    margin-right: 3px;
    line-height: 61px;
    text-align: center;
    color: #000;
    background-color: #fbe696;
    font-size: 1.28em;
  }

  a.active {
    background-color: $yellow;
  }
}

.yellow-search-under {
  border: 0;
}

.yellow-search-form-container {
  padding: 30px;
  background-color: #fafafa;
  margin-bottom: 60px;
  input, select {
    background-color: #eeeeee;
    border: 0;
    padding: 0 10px;
    height: 48px;
    width: 364px;
  }

  select, .to-left {
    margin-left: auto;
  }

  .left, .right {
    width: 48.3333%;

    .row {
      align-items: center;
      margin-bottom: 15px;
    }
  }

  .right {
    width: 45%;
    margin-left: auto;
  }

  .bottom {
    margin-left: auto;
  }

  .small-input, .datepicker {
    width: 163px;
  }

  input[type=submit] {
    width: 250px;
    background-color: #1B74B6;
    color: #fff;
    -webkit-transition: all 0.4s;
    transition: all 0.4s;
    margin-right: 0;
    border-radius: 30px;
    border: 2px solid #1B74B6;
    height: 50px;
  }

  label {
    margin: 0 6px;
  }

  a.mapa {
    display: inline-block;
    width: 250px;
    padding: 0 10px;
    height: 50px;
    border-radius: 30px;
    border: 2px solid #1B74B6;
    color: $blue;
    text-align: center;
    line-height: 50px;
    margin-right: 10px;
  }
}

.yellow-search-results {
  margin-bottom: 60px;

  .result-block {
    margin: 0 13px 90px 0;
    width: 375px;
    .thumbnail {
      width: 375px;
      height: 325px;
    }

    .opis {
      text-align: center;
      background-color: $yellow;
      min-height: 61px;
      line-height: 1.1;
      padding: 20px 10px;
      font-size: 1.42em;
    }

    .content {
      margin-top: 15px;

      table {
        margin-bottom: 20px;
        font-size: 1.42em;
        tr {
          td:first-child {
            padding-right: 6px;
          }
          td:last-child {
            font-weight: bold;
          }
        }
      }
    }

    a.link {
      display: block;
      width: 250px;
      padding: 0 10px;
      height: 50px;
      border-radius: 30px;
      border: 1px solid #1B74B6;
      color: $blue;
      text-align: center;
      line-height: 50px;
      margin: auto;

      &:hover {
        background-color: $blue;
        color: #fff;
      }
    }
  }

  .inner-page-pagination {
    margin: auto;
    max-width: 300px;
  }
}

.yellow-single-property {
  .content {
    flex: 1;
    margin-right: 30px;

    .yellow-block {
      background-color: $yellow;
    }

    .light-yellow-block {
      background-color: #fbe79a;
    }

    .middle-title.yellow-block {
      padding: 15px;
    }

    .lines {
      margin-bottom: 4px;
      font-size: 1.28em;
      .line {
        display: flex;

        .light-yellow-block, .yellow-block {
          display: flex;
          align-items: center;
          justify-content: center;
          min-height: 61px;
          padding: 5px 10px;
        }

        .light-yellow-block {
          min-width: 170px;
          text-align: center;
        }

        .yellow-block {
          flex-direction: column;
          text-align: center;
          flex: 1;
          span {
            display: block;
            font-size: 10px;
          }
        }
      }
    }

    .bottom-block {
      border: 1px solid #A9A9A9;
      padding: 30px;
      .row {
        margin-bottom: 10px;
        span, strong {
          width: 50%;
          line-height: 1.1;
        }
      }
    }

    table {
      margin-left: 15px;
      margin-bottom: 30px;

      tr {
        td:first-child {
          padding-right: 8px;
        }

        td:last-child {
          font-weight: bold;
        }
      }
    }
  }

  .img-links {
    width: 555px;

    .tabs {
      a {
        display: inline-block;
        margin-right: 3px;
        color: #fff;
        padding: 9px 30px;
        background-color: #707070;
      }

      a:hover {
        background-color: #454545;
      }

      a.active {
        background-color: #000;
      }
    }

    .thumbnail {
      width: 555px;
      height: 415px;
      margin-bottom: 14px;
    }

    .tabs-content {
      .tab {
        display: none;
      }

      .tab.active {
        display: block;
      }
    }

    .file {
      display: flex;
      align-items: center;
      margin-bottom: 3px;
      background-color: #efefef;
      padding: 10px 15px;
      color: #000;
      min-width: 20px;
      .custom-icon {
        margin-right: 25px;
      }
    }
  }
}

.yellow-single-property-table {
  margin-bottom: 40px;
  .line {
    display: flex;

    span:first-child {
      min-width: 240px;
      padding-right: 15px;
    }
    span:last-child {
      font-weight: bold;
    }
  }
}

.yellow-single-details {
  margin-bottom: 30px;
  background-color: #fbe79a;
  padding: 30px;

  .row {
    span {
      width: 240px;
    }

    .date-time, .type-ofert {
      font-weight: bold;
    }

    .type-ofert {
      margin-bottom: 15px;
    }

    a {
      color: #000;
    }

    a.file {
      margin-bottom: 6px;
      padding: 9px 13px;
      display: flex;
      align-items: center;
      background-color: $yellow;
      .custom-icon {
        margin-right: 12px;
        min-width: 20px;
      }
    }

    a.see {
      margin-top: 14px;
      text-decoration: underline;
    }
  }
}

/****************************************************/
@media screen and (max-width: 1640px) {
  .yellow-search-results {
    .row {
      justify-content: center;
    }
  }

  .yellow-single-property .img-links {
    width: 400px;
    .tabs a {
      padding: 5px 9px;
    }

    .thumbnail {
      width: 400px;
      height: 320px;
    }
  }
}

@media screen and (max-width: 991px) {

  .yellow-search-form-container {
    .left, .right {
      width: 100% !important;
    }
  }

  .yellow-gallery {
    .small-thumb {
    }

    .high-thumb {
    }
    .big-thumb {
    }
  }

  .yellow-rada-block {
    flex-wrap: wrap;
    .content {
      flex: 1 1 100%;
      margin-right: 0;
      margin-bottom: 30px;
    }
  }
}

@media screen and (max-width: 767px) {

  .yellow-single-property {
    > .row {
      flex-direction: column-reverse;

      .img-links {
        margin-bottom: 30px;
        width: 100%;
      }
    }
  }

  .yellow-search-results .result-block {
    margin-right: 0;
  }

  .yellow-search-form-container select, .yellow-search-form-container .to-left {
    width: 100%;
  }

  .yellow-search-form-container a.mapa {
    margin-bottom: 15px;
  }

  .yellow-search-up .row div {
    margin-left: 0;
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .yellow-files-download .line {
    flex-wrap: wrap;
    padding: 10px;
    span.name {
      width: 100%;
      margin-bottom: 25px;
      display: block;
    }

    a.download {
      margin-left: 0;
    }
  }
}

@media screen and (max-width: 575px) {

  .yellow-single-property .content .bottom-block {
    padding: 10px;
  }

  .yellow-single-details {
    padding: 10px;
  }

  .yellow-single-property-table .line {
    flex-wrap: wrap;
    span {
      width: 100% !important;
    }
  }

  .yellow-single-property .content .lines .line {
    flex-wrap: wrap;
    .light-yellow-block, .yellow-block {
      min-width: 100%;
    }
  }

  .yellow-search-form-container input, .yellow-search-form-container select {
    width: 100%;
  }

  .yellow-search-form-container .small-input, .yellow-search-form-container .datepicker {
    margin-bottom: 5px;
  }

  .yellow-search-form-container label {
    width: 100%;
  }

  .yellow-rada-block {
    .middle-title {
      max-width: 100%;
      margin-right: 0;
      margin-bottom: 15px;
    }

    .thumbnail {
      width: 290px;
      height: 280px;
    }
  }
}

@media screen and (max-width: 460px) {
  .yellow-single-property .img-links .thumbnail {
    width: 290px;
    height: 250px;
  }
}

@media screen and (max-width: 430px) {
  .yellow-search-results .result-block {
    width: 290px;
    .thumbnail {
      width: 290px;
      height: 240px;
    }
  }
}