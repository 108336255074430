@import "../base/colors";
@import "../helpers/mixins";

.admin-person {
  margin-bottom: 120px;
  .content {
    margin-right: 40px;
    flex: 1;

    .title {
      margin-bottom: 0;
    }

    .position {
      margin-bottom: 30px;
      font-size: 1.42em;
    }

    ul {
      list-style: disc;
      margin-bottom: 50px;
      padding-left: 15px;
      li {
        padding: 3px 0;
      }

    }
  }

  .ava-and-contant {
    width: 450px;
  }

  .thumbnail {
    width: 356px;
    height: 356px;
    margin: 0 auto 30px;
  }
}

.admin-person.reverse {
  .row {
    flex-direction: row-reverse;
  }

  .content {
    margin-right: 0;
    margin-left: 40px;
  }
}

.admin-link-block {
  max-width: 450px;
  margin-bottom: 90px;
  .name {
    font-size: 1.83em;
    margin-bottom: 10px;
    font-weight: 600;
    line-height: 1.1;
  }
}

.admin-small-person-block {
  display: flex;
  margin-bottom: 60px;
  .thumbnail {
    width: 139px;
    height: 139px;
  }

  a {
    color: #000;
  }

  .content {
    flex: 1;
    margin-left: 30px;

    .name {
      font-size: 1.85em;
      font-weight: 600;
      margin-bottom: 10px;
      line-height: 1.1;
    }
  }

}

@media screen and (max-width: 1630px) {
  .admin-person .ava-and-contant {
    width: 350px;
    .thumbnail {
      width: 290px;
      height: 290px;
    }
  }
}

@media screen and (max-width: 991px) {
  .admin-person {
    .content {
      flex: 1 1 100%;
      margin: 0 0 30px !important;
    }

    .ava-and-contant {
      width: 100%;
      .place-adress-contacts {
        margin: auto;
      }
    }
  }
}

@media screen and (max-width: 560px) {
  .admin-link-block .name {
    font-size: 1.3em;
  }

  .admin-small-person-block .thumbnail {
    width: 110px;
    height: 110px;
  }
}