@import "../base/colors";
@import "../helpers/mixins";

.aktualnosc .image .date {
  display: none;
}

/**/
.news-small-title {
  line-height: 1.2;
  font-weight: 500;
  font-size: 2em;
  margin-bottom: 10px;
}

.news-big-tiles-container {
  margin: 50px auto;

  .small-tile {
    width: calc(50% - 7.5px);
    margin-right: 15px;
    margin-top: 15px;
    height: 465px;
    .content {
      left: 45px;
      right: 115px;
    }
  }

  .small-tile.no-margin {
    margin-right: 0;
  }

  .big-tile {
    width: 100%;
    height: 460px;
    .content {
      left: 45px;
      max-width: 70%;
    }
  }

}

.right-tiles-container {
  padding-left: 7.5px;
  > .row {
    margin: 0;
  }
}

.biggest-tile-container {
  padding-right: 7.5px;

  .biggest-tile {
    width: 527px;
    height: 940px;
    .content {
      left: 70px;
      right: 70px;
    }
  }
}

.news-big-tile {
  position: relative;

  .content, .date {
    position: absolute;
  }

  .date {
    display: none;
  }

  .content {
    color: #fff;
    bottom: 60px;

    .title {
      font-size: 2.14em;
      font-weight: bold;
      margin-bottom: 30px;
      line-height: 1.2;
    }

    .text {
      font-size: 1em;
      margin-bottom: 40px;
    }

    .read-all {
      display: inline-block;
      text-align: center;
      min-width: 250px;
      height: 50px;
      line-height: 50px;
      color: #fff;
      border: 1px solid #fff;
      background-color: inherit;
      border-radius: 20px;
      @include transition(all, .4s);

      font-family: 'Lato', sans-serif;
    }
  }

  .date {
    right: 0;
    top: 0;
    height: 80px;
    min-width: 80px;
    color: #fff;
    text-align: center;
    padding-top: 11px;
    .number {
      font-size: 2.14em;
      font-weight: bold;
      line-height: 1.1;
      padding: 0 10px;
    }

    .month {
      font-weight: bold;
      line-height: 1.1;
      font-size: 1.85em;
      padding: 0 10px;
    }
  }
}

.news-big-tile.blue {
  _-webkit-box-shadow: inset 0 0 0 2000px rgba(1, 137, 207, 0.5);
  _box-shadow: inset 0 0 0 2000px rgba(1, 137, 207, 0.5);
  background-color: rgba(1, 137, 207, 0.995);
  background-blend-mode: multiply;
  .date {
    background-color: $light-blue;
  }

  &:hover {
    .read-all {
      background-color: $blue;
      border-color: $blue;
    }
  }

  .read-all:hover {
    color: #fff;
  }
}

.news-big-tile.yellow {
  _-webkit-box-shadow: inset 0 0 0 2000px rgba(246, 208, 50, 0.5);
  _box-shadow: inset 0 0 0 2000px rgba(246, 208, 50, 0.5);
  background-color: rgba(246, 208, 50, 0.995);
  background-blend-mode: multiply;
  .date {
    background-color: $yellow;
  }

  &:hover {
    .read-all {
      background-color: $yellow;
      border-color: $yellow;
    }
  }

  .read-all:hover {
    color: #fff;
  }
}

.news-big-tile.white {
  _-webkit-box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.5);
  _box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.5);
  background-color: rgba(0, 0, 0, 0.5);
  background-blend-mode: multiply;
  .date {
    background-color: #fff;
    color: #000;
  }

  &:hover {
    .read-all {
      background-color: #fff;
      border-color: #fff;
      color: #000 !important;
    }
  }

  .read-all:hover {
    color: #000 !important;
  }
}

.news-big-tile.orange {
  _-webkit-box-shadow: inset 0 0 0 2000px rgba(133, 130, 53, 0.5);
  _box-shadow: inset 0 0 0 2000px rgba(133, 130, 53, 0.5);
  background-color: rgba(133, 130, 53, 0.995);
  background-blend-mode: multiply;
  .date {
    background-color: $orange;
  }

  &:hover {
    .read-all {
      background-color: $orange;
      border-color: $orange;
    }
  }

  .read-all:hover {
    color: #fff;
  }
}

.news-small-tiles-container {
  .single-tile {
    margin-bottom: 45px;
    .link {
      color: #000;
    }

    .thumbnail {
      width: 260px;
      height: 260px;
    }

    .content {
      flex: 1;
      margin-left: 15px;
      max-width: 45%;
      min-width: 45%;
      position: relative;
      padding-bottom: 40px;
      .meta {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;

        .social {
          margin-left: 20px;

          .icon-print {
            margin-right: 7px;
          }

          .fa-share-alt {
            margin-left: 7px;
          }
        }
      }
    }

    .title {
      font-weight: 500;
      font-size: 2.57em;
      margin-bottom: 10px;
    }
  }
}

//* MEDIA *************************************************/
@media screen and (max-width: 1640px) {
  .news-big-tile .content {
    bottom: 30px;
    .title {
      margin-bottom: 20px;
    }

    .text {
      line-height: 1.1;
      margin-bottom: 20px;
    }
  }

  .biggest-tile-container .biggest-tile {
    width: 367px;
    height: 663px;
    .content {
      left: 30px;
      right: 30px;
    }
  }

  .biggest-tile {
    height: 100%;
    img {
      height: 100%;
      width: 100%;
    }
  }

  .news-big-tiles-container .small-tile {
    height: 323px;
    .content {
      left: 30px;
      right: 85px;
    }

    .title {
      font-size: 1.7em;
    }
  }

  .news-big-tiles-container .big-tile {
    height: 325px;
    .content {
      left: 30px;
      width: 100%;
      right: 85px;
    }
  }

  .news-small-tiles-container .single-tile .thumbnail {
    width: 230px;
    height: 230px;
  }
}

/**/
.single-tile-container {
  .middle-title-and-link .read-all {
    color: $blue !important;
    &:hover {
      color: #fff !important;
    }
  }
}

@media screen and (max-width: 1440px) {
  .news-small-title {
    font-size: 1.5em;
  }

  .news-big-tile .content .title {
    font-size: 1.5em;
  }
}

@media screen and (max-width: 1199px) {
  .biggest-tile-container {
    margin-bottom: 15px;
  }

  .right-tiles-container {
    padding-left: 15px;
  }

  .biggest-tile-container .biggest-tile {
    height: 829px;
    width: 472px;
  }

  .news-big-tiles-container .big-tile {
    height: 425px;
  }

  .news-big-tiles-container .small-tile {
    display: none;
  }

  .news-big-tiles-container {
    .responsive-part {
      display: block;
      padding-left: 7.5px;
    }

    .responsive-small-tile {
      display: block !important;
      margin: 0;
      width: 100%;
      height: 407px;
      overflow: hidden;
    }

    .responsive-small-tile:first-child {
      margin-bottom: 15px;
    }
  }
}

@media screen and (max-width: 991px) {
  .news-big-tiles-container {
    .responsive-small-tile {
      height: 302px;
    }
  }

  .biggest-tile-container .biggest-tile {
    height: 618px;
    width: 352px;
  }

  .news-big-tiles-container .big-tile {
    height: 330px;
  }
}

@media screen and (max-width: 991px) and (min-width: 767px) {
  .news-big-tiles-container {
    .responsive-small-tile {
      .text {
        font-size: .9em;
      }

      .title {
        line-height: 1.1;
        font-size: 1.5em;
      }
    }
  }
}

@media screen and (max-width: 767px) {

  .news-small-tiles-container .single-tile .title {
    font-size: 1.8em;
  }

  .news-small-tiles-container .single-tile .content {
    max-width: 60%;
    min-width: 60%;
  }

  .news-big-tiles-container > .row {
    margin: 0;
  }

  .biggest-tile {
    height: auto;
    max-height: none;
  }

  .biggest-tile-container,
  .right-tiles-container {
    padding: 0;
  }

  .biggest-tile-container .biggest-tile {
    height: 718px;
    width: 100%;
  }

  .news-big-tiles-container .responsive-part {
    padding: 0;
  }

  .news-big-tiles-container .responsive-small-tile {
    height: 420px;
    margin-bottom: 15px;
  }

  .news-big-tiles-container .big-tile {
    height: 420px;
  }

  .news-small-tiles-container .single-tile {
    .content {
      margin-top: 10px;
      max-width: 100%;
      min-width: 100%;
      margin-left: 0;
      padding-left: 15px;
      padding-right: 15px;

      .meta {
        padding-left: 15px;
      }
    }
  }
}

@media screen and (max-width: 575px) {
  .news-small-tiles-container .single-tile {
    .row {
      margin: 0 15px;
    }
  }

  .news-big-tiles-container .responsive-small-tile:first-child,
  .news-big-tiles-container .responsive-small-tile,
  .biggest-tile-container {
    margin-bottom: 6px;
  }

  .news-small-title,
  .news-small-tiles-container .single-tile .title {
    font-size: 2.57em;
  }

  .news-small-tiles-container .single-tile .content {
    padding-left: 0;
    padding-right: 0;

    .meta {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .news-big-tiles-container {
    margin-top: 0;
  }

  .news-big-tiles-container .responsive-small-tile {
    min-height: 420px;
    height: auto;
    padding-top: 80px;
    display: flex !important;
  }

  .news-big-tiles-container .big-tile {
    min-height: 420px;
    height: auto;
    padding-top: 80px;
    display: flex !important;
  }

  .biggest-tile-container .biggest-tile {
    padding-top: 80px;
    display: flex !important;
  }

  .news-big-tile {
    padding-top: 80px;
    .content {
      max-width: 100% !important;
      position: static;
      margin: auto 0 30px !important;
      padding: 0 30px;
      .title {
        font-size: 2.14em;
      }

      .read-all {
        display: block;
        margin: auto;
        width: 100%;
        min-width: 340px;
      }
    }
  }

  .biggest-tile-container .biggest-tile,
  .news-big-tiles-container .small-tile {
    > a {
      height: 100%;
      display: flex;
    }

    .content {
      width: 100%;
      position: static;
      margin: auto 0 30px !important;
    }
  }
}

@media screen and (max-width: 425px) {
  .news-big-tile .content .read-all {
    min-width: 260px;
  }
}

@media screen and (max-width: 375px) {

  .news-big-tile .content {
    padding: 0 15px;
  }

  .news-small-tiles-container .single-tile .row {
    margin: 0;
  }
}

@media screen and (max-width: 350px) {

}