@import "../base/colors";
@import "../helpers/mixins";

.single-event-gallery {
  margin-bottom: 100px;
}

.inner-page-section.bottom-bg {
  min-height: 1294px;
}

.inner-page-section {
  min-height: 900px;
  padding-bottom: 40px;
  background-repeat: no-repeat;
  position: relative;

  .urzad-miejski-block {
    color: #000;
    a {
      color: #000;
    }

    &:hover {
      background-color: #fbfbfb;
    }

    display: flex;
    border: 1px solid #fbfbfb;
    margin-bottom: 10px;
    min-height: 185px;
    .image {
      background-color: #fbfbfb;
      background-repeat: no-repeat;
      background-position: 50% 50%;

      width: 50%;

      display: flex;
      align-items: center;
      justify-content: center;
    }

    .text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 18px 50px;
      width: 50%;

      p {
      }
      ul {
        padding: 0;
        margin-bottom: 10px;
        list-style: none;
      }
    }
  }

  .urzad-miejski-block.first {
    .image {
      background-image: url('urzad-miejski-1.jpg');
    }
  }

  .urzad-miejski-block.second {
    .image {
      background-image: url('urzad-miejski-2.jpg');
    }
  }

  .urzad-miejski-block.third {
    .image {
      background-image: url('urzad-miejski-3.jpg');
    }
  }

  .fifteen-percent-margin-left {
    max-width: 50%;
    margin-left: 16%;
    margin-bottom: 30px;
  }

  ul {
    li {
      padding: 3px 0;
    }
  }

  .contact-block {
    margin-bottom: 90px;
    .for {
      font-weight: bold;
      font-size: 1.78em;
      margin-bottom: 50px;
    }
    .subtitle {
      font-weight: bold;
      font-size: 1.28em;
      margin-bottom: 15px;
    }

    ul {
      font-size: 1.4em;
      margin-bottom: 30px;
      li {
        padding: 3px 0;
      }

      .custom-icon {
        margin-right: 7px;
      }

      a {
        color: #000;
      }

      span:not(.name) {
        font-size: 14px;
      }

      .tel {
        font-weight: bold;

      }
    }
  }

  .hymn-container {
    position: relative;
    top: -115px;

    .title {
      font-size: 1.6em;
    }

    .title:first-child {
      margin-bottom: 0;
      font-size: 2.14em;
    }

    .song-container .wide-column {
      max-width: 75%;
      width: 75%;
      padding: 0 15px;

      .border-image {
        text-align: right;
      }
    }
  }

  .place-adress-contacts {
    margin-left: auto;
    max-width: 518px;
    .place {
      font-weight: bold;
      font-size: 1.78em;
      border-radius: 40px;
      border: 1px solid $grey;
      padding: 10px;
      margin-bottom: 25px;
      text-align: center;

      a {
        font-size: 0.5em;

      }
    }

    .info {
      padding: 10px 35px;
      margin-bottom: 25px;
    }

    table {
      margin-bottom: 15px;
      td:first-child {
        padding-right: 7px;
      }

      td:last-child {
        font-weight: bold;
      }
    }

    .contacts {
      li {
        padding: 5px 0;
        display: flex;
        align-items: center;
      }

      .tel {
        font-weight: bold;
        font-size: 1.42em;
      }

      .custom-icon {
        margin-right: 8px;
        width: 30px;
      }

      border-radius: 40px;
      background-color: $light-blue;
      color: #fff;
      padding: 15px 30px;
      a {
        color: #fff;
      }
    }

    .unvisible-place {
      opacity: 0;
    }

    .contacts-and-name {
      min-height: 167px;
      display: flex;
      border-radius: 40px;
      background-color: $light-blue;
      padding: 15px 30px;
      color: #fff;
      align-items: center;
      > a {
        margin-left: auto;
        margin-right: 60px;
      }

      .name {
        color: #fff;
        width: 50%;
        font-weight: bold;
        line-height: 1.1;
        font-size: 1.3em;
        span {
          font-weight: normal;
        }
      }
      .contacts {
        border-radius: 0;
        padding: 0;
        margin: 0;
      }
    }

  }

  .blue-block, .grey-block {
    display: flex;
    width: 248px;
    align-items: center;
    justify-content: center;
    min-height: 51px;
    text-align: center;
    padding: 5px;

  }

  .grey-block {
    color: $grey;
    background-color: $light-grey;
  }

  .line {
    display: flex;
    margin-bottom: 3px;
  }

  .hight-row {
    min-height: 50px;
  }

  .line-row {
    display: flex;
    span {
      padding-right: 10px;
      width: 50%;
      font-weight: 600;
    }

    > div {
      flex: 1;
    }
    .custom-icon {
      margin-right: 8px;
    }
  }

  .map-container {
    // min-height: 700px;
    position: relative;
    margin-left: auto;
    margin-bottom: 60px;
    width: 92.5%;

    ul {
      list-style: none;
      padding: 0;
      li {
        margin-bottom: 3px;
      }
      .blue-block:hover {
        background-color: $blue;
      }
    }

    .years-container {
      display: flex;

      img {
        position: relative;
        left: -45px;
        top: 45px;
        z-index: -1;
      }
    }
    ul.years {
      z-index: 6;
      position: relative;
    }

    .external-container {
      display: flex;
    }

    ul.external-links {
      margin-right: 100px;
      position: static;
      margin-left: auto;
      .blue-block, .grey-block {
        width: 387px;
      }
    }
  }

  .blue-block {
    color: #fff;
    font-weight: bold;
    font-size: 0.85em;
    background-color: $light-blue;
  }

  .iframe {
    margin: 30px 0;
  }

  .full-width-image {
    width: 100%;
    position: relative;
    margin: 20px 0;
    img {
      position: relative;
      z-index: -1;
      top: 0;
      left: 0;
    }
  }

  .full-width-image-with-text {
    overflow: hidden;
    .text {
      padding-top: 40px;
      padding-bottom: 40px;
      position: static;
      width: 943px;
      margin: auto;
    }

    img {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
    }
  }

}

.inner-page-pagination {
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  .pages-counter {
    margin-left: 15px;
  }

  .pages {
    margin: 0 10px;

    a {
      color: $grey;
      padding: 0 5px;
    }

    a:hover {
      color: #000;
    }

    a.active {
      color: #000;
    }
  }
}

.inner-page-section.yellow-inner-page-section {
  min-height: 2100px;
}

.inner-page-section.bottom-bg {
  background-position: center bottom;
}

.inner-page-menu-container {
  width: 330px;
  position: relative;

  .burger {
    display: none;
    position: absolute;
    right: 0;
    top: 0;
    font-size: 1.5em;
    cursor: pointer;
    em, .fa {

    }
  }

  ul.inner-page-menu {
    width: 100%;
    list-style: none;
    padding: 0;
    font-size: 1.28em;
    font-weight: bold;
    li {
      width: 100%;
      margin-bottom: 4px;
    }

    a {
      position: relative;
      display: inline-block;
      width: 100%;
      height: 100%;
      padding: 16px 20px;
      color: $grey;

      .fa {
        position: absolute;
        right: 10px;
        top: 10px;
        font-size: 2em;
        color: $grey;
      }
    }

    li {
      a {
        background-color: #e1e1e1;
        color: #6f6f6f;
        border: 1px solid #e1e1e1;
      }
    }

    li:hover {
      a {
      }

      ul.submenu {
        display: block;
      }

      .fa {
        &:before {
          content: "\f107";
        }
      }
    }

    li.active {
      a {
        background-color: #707070;
        color: #fff;
      }
      ul.submenu {
        display: block;
      }

      .fa {
        &:before {
          content: "\f107";
        }
      }
    }

    ul.submenu {
      list-style: none;
      padding-left: 0;
      display: none;
      margin-top: 4px;
      margin-left: 8px;
      font-weight: normal;

      a {
        color: #000;
        background: #f2f2f2;
        border: 1px solid #f2f2f2;
      }

      li.active {
        a {
          background-color: #b8d3df;
          color: #000;
        }

        ul.submenu {
          display: block;
          margin-left: 16px;
        }
      }

      li:hover {
        a {
          background-color: #e1e1e1;
        }

        ul.submenu {
          display: block;
        }
      }

      ul.submenu {
        display: none;
        a {
          color: #000;
          background: #d8d6d6;
          border: 1px solid #d8d6d6;
        }

        li.active {
          a {
            background-color: #83b4c9;
            color: #000;
          }
        }

        li:hover {
          a {
            background-color: #cac6c6;
          }
        }
      }

    }
  }

  ul.blue-menu {

    li a {
      background-color: $light-blue;
      color: #7bd5f9;
      border: 1px solid $light-blue;
      .fa {
        color: #7bd5f9;
      }
    }

    li a.active {
      background-color: $light-blue;
      color: #fff;

      .fa {
        color: #fff;
      }
    }

    li:hover a {
      background-color: $light-blue;
      color: #fff;
      .fa {
        color: #fff;
      }
    }

    li.active {
      a {
        background-color: $light-blue;
        color: #fff;
      }
    }

    ul.submenu {
      li a {
        color: #000;
        background-color: #1dbaff !important;
        border: 1px solid #1dbaff !important;
        .fa {
          color: #000;
        }
      }

      li.active {
        a {
          background-color: #1dbaff !important;
          color: #fff;
          .fa {
            color: #fff;
          }
        }
      }

      li a:hover {
        background-color: #1dbaff !important;
        color: #fff;
        .fa {
          color: #fff;
        }
      }
    }
  }

  ul.purple-menu {

    li a {
      background-color: $purple;
      color: #af6ba1;
      border: 1px solid $purple;
      .fa {
        color: #af6ba1;
      }
    }

    li:hover a {
      background-color: $purple;
      color: #fff;
      .fa {
        color: #fff;
      }
    }

    li.active {
      a {
        background-color: $purple;
        color: #fff;
        font-weight: bold;
        .fa {
          color: #fff;
        }
      }
    }

    ul.submenu {
      li a {
        color: #000;
        background-color: #aa2797 !important;
        border: 1px solid #aa2797 !important;
        .fa {
          color: #000;
        }
      }

      li.active {
        a {
          color: #fff;
          font-weight: bold;
          .fa {
            color: #fff;
          }
        }
      }

      li a:hover {
        color: #fff;
        .fa {
          color: #fff;
        }
      }
    }
  }

  ul.yellow-menu {
    li a {
      background-color: $yellow;
      color: #8b6b22;
      border: 1px solid $yellow;
      .fa {
        color: #8b6b22;
      }
    }

    li:hover a {
      background-color: $yellow;
      color: #fff;
      .fa {
        color: #fff;
      }
    }

    li.active {
      a {
        background-color: $yellow;
        color: #fff;
      }
    }

    ul.submenu {
      li a {
        color: #000;
        background-color: #fae798 !important;
        border: 1px solid #fae798 !important;

        .fa {
          color: #000;
        }
      }

      li.active {
        a {
          border: 1px solid #fae798 !important;
          background-color: #fae798 !important;
          color: #fff;
        }
      }

      li a:hover {
        background-color: #c6c6c6 !important;
        .fa {
          color: #fff;
        }
      }

      li:hover, li.active {
        ul.submenu {
          display: block !important;
        }
      }

      ul.submenu {
        display: none !important;
        li a {
          background-color: #ffefac !important;
          border-color: #ffefac !important;
          color: #000 !important;
        }

        li.active {
          a {
            background-color: #fdf0b8 !important;

          }
        }

        li:hover {
          a {
            background-color: #e0e0e0 !important;
          }
        }
      }
    }
  }
}

.inner-page-content-container {
  margin-left: 50px;
  width: 72.84%;
  padding-left: 15px;

  .sharethis-inline-share-buttons {
    margin: 30px 0;
  }

  .container {
    padding: 0;
  }

  a {
    color: #000;
  }

  .form-container {
    background-color: rgba(225, 225, 225, 0.46);
    padding: 20px 30px;
    margin-bottom: 60px;
    p.main-paragraph {
      margin-bottom: 10px;
    }

    .row {
      margin-bottom: 0;
    }

    input, select {
      border: none;
      border-radius: 20px;
      height: 42px;
      padding: 0 10px;
      margin-right: 17px;
      margin-bottom: 10px;
    }

    input[type=text] {
      width: 328px;
    }

    select {
      width: 240px;
    }

    input[type=submit] {
      width: 230px;
      background-color: #1B74B6;
      color: #fff;
      -webkit-transition: all 0.4s;
      transition: all 0.4s;
      margin-right: 0;
      border: 2px solid #1B74B6;
    }
  }

  ul.list-disc {
    list-style: disc;
    padding-left: 15px;
  }

  ol.list-num {
    padding-left: 15px;
  }

  .two-columns, .two-columns-no-title {
    margin: 30px 0 60px;
    .place-adress-contacts {
      margin-left: 0;
      margin-top: 20px;
    }
    .column {

      max-width: 50%;
      width: 50%;
      padding: 0 8px;

      ul {
        margin-bottom: 15px;
        li {
          padding: 2px 0;
        }
      }
    }

    .herb-column {
      max-width: 30%;
      width: 30%;
    }

    .herb-text-column {
      max-width: 70%;
      width: 70%;
    }

    .column.margin-bottom {
      margin-bottom: 60px;
    }

    .column-left {
      margin-right: 50%;
    }

    .column-right {
      margin-left: 50%;
    }

    .aqua-column {
      background-color: #e0f3fd;
      padding: 15px;
      margin-right: 8px;
      width: calc(50% - 4px);
      .line-row {
        padding: 7px 0;
      }

      a {
        color: #000;
      }
    }

    .aqua-column:last-child {
      margin-right: 0;
    }
  }

  .three-columns {
    margin: 30px 0 60px;

    > .small-title {
      max-width: 50%;
      margin-bottom: 30px;
      margin-left: auto;
      margin-right: auto;

    }

    .column {
      max-width: 33%;
      width: 33%;
      padding: 0 8px;
      margin-bottom: 30px;
    }

    ul {
      font-size: 1.28em;
      li {
        padding: 4px 0;
      }
    }

    .big-column {
      max-width: 50%;
      width: 50%;
    }
  }

  .three-columns.aqua {
    background-color: #e0f3fd;

    padding-top: 30px;
    padding-left: 15px;
    padding-right: 15px;

  }

  .song-container {
    margin-bottom: 40px;
    .column {
      max-width: 25%;
      padding-right: 15px;

      p {
        max-width: 250px;
        margin-bottom: 20px;
      }

    }

    .wide-column {
      max-width: 50%;
    }
  }

  .row {
    margin: 0;
    margin-bottom: 30px;
    > p {
      max-width: 50%;
      padding: 0 8px;
      margin-bottom: 18px;
    }

    p.small-paragraph {
      max-width: 33%;
    }

    p.small-paragraph.margin-left {
      margin-left: 30%;
    }
  }

  .single-tile-container {
    width: 50%;
    padding-right: 15px;
    .thumbnail {
      width: 240px;
      height: 233px;
    }
  }

  .small-title {
    font-size: 1.85em;
    font-weight: bold;
    line-height: 1.1;
    margin-bottom: 15px;
  }

  .middle-title,
  .middle-title-with-img {
    font-size: 2.14em;
    font-weight: bold;
    line-height: 1.1;
    margin-bottom: 30px;
  }

  .page-title, .title, .page-title-with-img {
    font-size: 2.14em;
    font-weight: bold;
    margin-bottom: 25px;
    line-height: 1.1;
  }

  .page-title-with-img {
    display: flex;
    // align-items: center;
    span {
      margin-right: 30px;
    }

    .border-image {
      margin-left: auto;
    }

    img {
      margin-left: auto;
    }
  }

  .middle-title-with-img {
    display: flex;
    // align-items: center;
    span {
      margin-right: 30px;
    }

    .border-image {
      margin-left: auto;
    }

    img {
      margin-left: auto;
    }
  }

  p.main-paragraph {
    font-size: 1.28em;
    margin-bottom: 30px;
  }

  p.big-paragraph {
    max-width: 80%;
    padding: 0;
  }

  .center-paragraph {
    margin: 30px auto;
    max-width: 60%;
  }

  .right-paragraph {
    margin-left: auto;
    max-width: 60%;
    margin-bottom: 40px;
  }

  .quote {
    font-weight: 600;
    line-height: 1.2;
    font-size: 1.73em;
    margin-top: 60px;
    margin-bottom: 60px;
    position: relative;
    z-index: 5;
    ul {
      margin-top: 30px;
    }
    .autor {
      position: absolute;
      right: 0;
      bottom: -19px;
      font-size: .6em;
    }
    &:before {
      content: '';
      display: block;
      position: absolute;
      width: 85px;
      height: 77px;
      background-image: url('../img/inner/quote-begin.png');
      background-repeat: no-repeat;
      left: -30px;
      top: -41px;
      z-index: -1;
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      width: 85px;
      height: 77px;
      background-image: url('../img/inner/quote-end.png');
      background-repeat: no-repeat;
      right: 0;
      bottom: -30px;
      z-index: -1;
    }
  }

  .quote-to-right {
    margin-left: 15%;
  }

  .quote-to-left {
    margin-right: 15%;
  }

  .photos {
    text-align: right;
    width: 100%;
    margin-bottom: 60px;
    display: flex;
    p {
      text-align: center;
    }

    a {
      width: 30.5%;
      img {
        width: 100%;
        height: 343px;
      }
    }

    .to-right {
      margin-left: auto;
      display: flex;
    }

    .border-image {
      margin-right: 3px;
      margin-bottom: 3px;
    }

    .thumbnail {
    }
  }

  .created-by {
    margin-top: 60px;
    margin-bottom: 20px;
    margin-left: auto;
    max-width: 60%;
    strong, span {
      display: block;
    }

    strong {
      font-size: 1.28em;
      margin-bottom: 10px;
    }
  }
}

/****************************************/
@media screen and (max-width: 1690px) {
  .inner-page-menu-container {
    left: 15px;
    width: 310px;
    ul.inner-page-menu {
      font-size: 1.1em;
    }
  }
}

@media screen and (max-width: 1640px) {

  .inner-page-section .hymn-container {
    position: static;
  }

  .inner-page-section .place-adress-contacts .contacts-and-name {
    flex-wrap: wrap;
    .name {
      //margin-bottom: 15px;
    }
  }

  .inner-page-content-container .photos a img {
    height: 230px;
  }

  .inner-page-content-container .single-tile-container {
    padding: 0;
    width: 100%;
  }

  .inner-page-content-container .two-columns .aqua-column .line-row {
    font-size: .85em;
  }

  .inner-page-section {
    .line {
      .blue-block, .grey-block {
        vertical-align: top;
        width: calc(50% - 2px);
        line-height: 1.1;
        padding: 10px 4px;
      }
    }
  }

  .inner-page-content-container {
    margin-left: 10px;
    width: 70%
  }

}

@media screen and (min-width: 1200px) and (max-width: 1320px) {
  .inner-page-menu-container {
    width: 250px;
  }
}

@media screen and (max-width: 1199px) {

  .inner-page-content-container .photos a img {
    width: 100%;
    height: 289px;
  }

  .inner-page-content-container .two-columns .aqua-column {
    margin-right: 0;
    width: 100%;
    max-width: 100%;
    margin-bottom: 8px;
  }

  .inner-page-section.bottom-bg {
    min-height: 1700px;
  }

  .inner-page-section .full-width-image-with-text {
    .text {
      padding: 40px 15px;
      width: 100%;
      margin: 0;
      max-width: 100%;
    }

    img {
      height: 100%;
    }
  }

  .inner-page-menu-container {
    position: relative;
    margin-bottom: 40px;
    width: 100%;
    left: 0;

    ul.inner-page-menu {
      width: 290px;
    }
  }

  .inner-page-content-container {
    margin-left: 0;
    padding-left: 0;
    width: 100%;
  }
}

@media screen and (max-width: 991px) {

  .inner-page-section .map-container .external-container {
    margin-top: 30px;
    .external-links {
      margin-left: 0;
    }
  }

  .inner-page-content-container .photos a img {
    width: 100%;
    height: 216px;
  }

  .inner-page-content-container .created-by {
    max-width: 80%;
  }

  .inner-page-content-container {
    .page-title, .title, .page-title-with-img {
      font-size: 3em;
    }
  }

  .inner-page-content-container .three-columns .column {
    max-width: 100%;
    width: 100%;
    margin-bottom: 25px;
  }

  .inner-page-section .map-container {
    width: 100%;
  }

}

@media screen and (max-width: 767px) {

  .inner-page-menu-container {
    ul.inner-page-menu {
      display: none;
    }

    .burger {
      display: block;
      font-size: 1.85em;
    }
  }

  .inner-page-section .urzad-miejski-block {
    flex-wrap: wrap;

    .image, .text {
      width: 100%;
    }

    .image {
      min-height: 150px;
      -webkit-background-size: 60%;
      background-size: 60%;
    }
  }

  .inner-page-section .map-container .years-container {
    flex-wrap: wrap;

    img {
      position: static;
    }
    .border-image {
      width: 100%;
    }
  }

  .inner-page-content-container .photos a img {
    height: auto;
  }

  .inner-page-content-container .two-columns {
    .column-left, .column-right {
      margin-left: 0;
      margin-right: 0;
      max-width: 100% !important;
      width: 100% !important;
    }
  }

  .inner-page-section .fifteen-percent-margin-left {
    max-width: 100%;
    margin-left: 0;
  }

  .inner-page-content-container .song-container .column {
    max-width: 100%;
    p {
      max-width: 100%;
      padding: 0;
    }
  }

  .inner-page-section {
    height: auto;
    .map-container {
      ul.external-links, img {
        position: static;
        display: block;
      }
    }
  }

  .inner-page-menu-container {
    margin-left: 0;
  }

  .inner-page-content-container {
    padding: 0 15px;
  }

  .inner-page-content-container .two-columns .aqua-column {

  }
}

@media screen and (max-width: 767px) {

  .inner-page-content-container .row p {
    max-width: 100%;
    padding: 0;
  }

  .inner-page-content-container .two-columns, .two-columns-no-title {
    .column {
      max-width: 100%;
      width: 100%;
      padding: 0;
    }
  }

  .inner-page-content-container .two-columns .aqua-column {
    padding: 8px;
  }

  .inner-page-content-container .row p.small-paragraph {
    max-width: 100%;
    margin-left: 0 !important;
  }

  .inner-page-content-container .right-paragraph, .inner-page-content-container .center-paragraph {
    max-width: 100%;
    margin: 20px 0;
  }

  .inner-page-content-container {
    .quote-to-right {
      margin-left: 0;
    }
    .quote-to-left {
      margin-right: 0;
    }
  }
}

@media screen and (max-width: 575px) {

  .inner-page-menu-container .burger {
    right: 15px;
  }

  .inner-page-menu-container {
    margin-left: 15px;
  }

  .inner-page-content-container .three-columns.aqua > .small-title {
    max-width: 90%;
  }
  .inner-page-content-container .small-title {
    font-size: 1.3em;
  }

  .inner-page-content-container .middle-title {
    font-size: 1.6em;
  }

  .inner-page-content-container .three-columns ul {
    font-size: 1em;
  }

  .inner-page-content-container .two-columns .aqua-column .line-row {
    flex-wrap: wrap;
    span {
      width: 100%;
      margin-bottom: 4px;
    }
  }

  .inner-page-section {
    .place-adress-contacts {
      .place {
        font-size: 1.3em;
      }

      .info {
        padding: 10px;
      }
    }
  }
}

@media screen and (max-width: 425px) {

  .inner-page-section .place-adress-contacts .contacts {
    padding: 15px 10px;
  }

  .inner-page-section .place-adress-contacts .contacts-and-name {
    padding: 15px 10px;
  }

  .inner-page-section .map-container {
    ul.external-links {
      .blue-block, .grey-block {
        width: 300px;
      }
    }
  }
}

@media screen and (max-width: 400px) {
  .inner-page-menu-container {
    padding-top: 35px;
  }
}

@media screen and (max-width: 375px) {
  .inner-page-menu-container {
  }

  .inner-page-content-container {
    .page-title, .title, .page-title-with-img {
      font-size: 2em;
    }
  }

}