@import "../base/colors";
@import "../helpers/mixins";

/**/
.categories {
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
  height: 89px;
  z-index: 20;
  > div {
    height: 100%;
  }

  .row {
    height: 100%;
  }

  .category-block {
    a {
      padding: 10px 10px 10px 12.69%;
      color: #fff;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;

      .fa {
        color: #fff;
        position: absolute;
        right: 40px;
        font-size: 2.14em;
        height: 40px;
        top: 50%;
        margin-top: -20px;
      }
    }
  }

  .cat-section {
    background-color: #fff;
    border-bottom: 4px solid #000;
    padding: 30px 15px;
    display: none;

    ul {
      margin-bottom: 15px;
      padding: 0;
      list-style: none;

      li {
        line-height: 20px;
        padding-left: 20px;
      }

      li:first-child {
        padding-left: 0;
      }

    }
  }

  .cat-section.active {
    display: block;
  }

  .cat-section.cat-purple {
    border-color: $purple;

    a.bold {
      color: $purple;
    }
  }

  .cat-section.cat-blue {
    border-color: $blue;

    a.bold {
      color: $blue;
    }
  }

  .cat-section.cat-yellow {
    border-color: $yellow;
    a.bold {
      color: $yellow;
    }
  }

  .for {
    opacity: .5;
  }

  .for, .cat {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 2.14em;
    line-height: 1.2;
  }

  .blue {
    background-color: $light-blue;
  }

  .yellow {
    background-color: $yellow;
  }

  .purple {
    background-color: $purple;
  }
}

.under-categories-section {
  background-color: #F1F1F1;
  padding-bottom: 4px;
  .container {
    background-color: #fff;
    border-bottom: 4px solid $purple;
    padding-top: 50px;
    display: none;
  }

  .container.active {
    display: block;
  }

  &-block {
    padding-left: 15px;
    padding-right: 15px;
    font-size: 1.42em;
    margin-bottom: 50px;
    max-width: 25%;
    width: 25%;
    a {
      color: #000;
      padding: 2px 0;
      width: 100%;
      height: 100%;
      display: inline-block;

      @include transition(all, .4s);
    }

    a:hover {
      font-weight: 500;
    }

    a.bold {
      font-weight: bold;

    }

    a.bold:hover {
      font-weight: 800;
    }

    ul {
      margin-bottom: 15px;
      padding: 0;
      list-style: none;
      li {
        line-height: 20px;
        padding-left: 20px;
      }

      li:first-child {
        padding-left: 0;
      }

      ul {
        display: none;
      }
    }

    ul:hover {
      ul {
        display: block;
      }
    }

    ul:last-child {
      margin-bottom: 0;
    }
  }
}

#category-section-1 {
  border-color: $purple;
  a.bold {
    color: $purple;
  }
}

#category-section-2 {
  border-color: $yellow;
  a.bold {
    color: $yellow;
  }
}

#category-section-3 {
  border-color: $blue;
  a.bold {
    color: $blue;
  }
}

/* MEDIA ***********************/

@media screen and (max-width: 1630px) {

}

@media screen and (max-width: 1440px) {
  .categories .for, .categories .cat {
    font-size: 2em;
  }
}

@media screen and (max-width: 1199px) {
  .categories {
    .for, .cat {
      font-size: 2em;
    }
    .category-block {
      a {
        padding-left: 15px;
        .fa {
          right: 15px;
        }
      }
    }
  }
}

@media screen and (max-width: 991px) {

  .under-categories-section-block {
    max-width: 50%;
    width: 50%;
  }

  .categories .for, .categories .cat {
    font-size: 1.5em;
  }

  .categories {
    height: 71px;
  }
}

@media screen and (max-width: 767px) {
  .under-categories-section-block {
    max-width: 100%;
    width: 100%;
    padding: 0 15px;
    font-size: 1.3em;
    margin-bottom: 0;
  }

  .under-categories-section .container {
    padding-bottom: 30px;
  }

  .category-block {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .categories .for, .categories .cat {
    display: block;
    font-size: 1.78em;
  }

  .categories {
    height: auto;

    .row {
      height: auto;
    }

    .category-block {
      height: 72px;
      a {
        padding: 10px 30px;
        .fa {
          margin-top: -24px;
          font-size: 3.4em;
        }
      }

    }
  }
}

@media screen and (max-width: 575px) {
  .categories {
    margin-top: auto;
    position: static;
  }

  .under-categories-section {
    display: none !important;
  }
}

@media screen and (max-width: 375px) {
  .categories .category-block a {
    padding: 10px 15px;
  }
}