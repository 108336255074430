@import "../base/colors";
@import "../helpers/mixins";

footer {
  color: #fff;

  a {
    color: #fff;
  }

  ul {
    padding: 0;
    list-style: none;
  }
}

.footer-top {
  padding: 35px 15px;
  background-color: #393434;
  font-weight: 300;
  .widget-social li {
    display: inline-block;
    margin-right: 10px;

    ul {
      display: inline-block;
      margin-left: 20px;
    }
  }

  a:hover {
    color: $grey;
  }

  .widget-menu li {
    line-height: 35px;
  }

  .newsletter-reg {
    width: 536px;
    height: 52px;
    margin-bottom: 40px;
    font-size: 1.28em;
    display: flex;

    input[type=submit] {
      background-color: $blue;
      color: #fff;
      height: 100%;
      width: 200px;
      border: 0;
      cursor: pointer;
    }

    .textfield-container {
      position: relative;
      background-color: #fff;
      flex: 1;

      input {
        border: 0;
        padding: 0 15px;
        width: 100%;
        height: 100%;
      }

      .placeholder {
        cursor: pointer;
        position: absolute;
        top: 0;
        left: 0;
        padding-left: 17px;
        padding-top: 13px;
        width: 100%;
        height: 100%;
        color: #000;
        font-weight: normal;
        text-transform: uppercase;
      }
    }
  }
}

.footer-bottom {
  padding: 10px 0;
  background-color: #000;

  p {
    font-size: 8px;
    font-weight: 300;
    margin: 0;
    color: #fff;
    span {
      margin-right: 20px;
    }
  }
}

@media screen and (max-width: 1630px) {
  .footer-top .newsletter-reg {
    width: 100%;
    max-width: 536px;
    input[type=submit] {
      width: 150px;
    }

    .textfield-container {
      .placeholder {
        padding-left: 11px;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .footer-top .newsletter-reg .textfield-container .placeholder {
    font-size: 13px;
    top: 5px;
  }
}

@media screen and (max-width: 768px) {
  .footer-top {
    .widget-menu {
      margin: 15px 0;
    }
  }
}

@media screen and (max-width: 600px) {
  .footer-top {
    padding: 35px 0;
  }
}

@media screen and (max-width: 490px) {
  .footer-top .newsletter-reg {
    flex-wrap: wrap;
    height: auto;
    .textfield-container,
    input[type=submit] {
      width: 100%;
      height: 52px;
    }

    .textfield-container .placeholder {
      padding: 0 10px;
      text-align: center;
      top: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 18px;
      strong{
        padding-right: 5px;
      }
    }
  }
}

@media screen and (max-width: 375px) {
  .footer-top .newsletter-reg .textfield-container .placeholder {
    font-size: 13px;
    line-height: 1.1;
    top: 1px;
  }
}