
.purple-kulturalne-block {
  .column {
    img {
      margin-bottom: 15px;
    }
  }
}

.purple-map-block {
  display: flex;
  background-color: #ead7e6;
  margin-bottom: 20px;

  .purple-map-container, .thumbnail {
    width: 248px;
    height: 248px;
  }

  ul{
    margin: 0;
    padding: 0;
    list-style: none;
  }

  .thumbnail {
    margin-left: auto;
  }

  .responsive {
    display: none;
  }

  .content-container {
    flex: 1;
    padding: 20px 30px;
    display: table;
    .content {
      display: table-cell;
      vertical-align: bottom;
    }
  }

  .name {
    font-weight: bold;
    font-size: 1.78em;
    margin-bottom: 25px;
  }

  ul {
    li {
      padding: 3px 0;
    }

    .bold {
      font-weight: bold;
    }

    a {
      color: #000;
      display: flex;
      align-items: center;
    }

    .custom-icon {
      margin-right: 7px;
    }
  }

  .purple-map-container {
    margin-right: auto;
  }
}

.inner-page-table-purple {
  .head {
    background-color: $purple;
    color: #fff;

    .circle {
      background-color: #ab669f;
      border-color: #ab669f;
    }
  }

  span.num {
    background-color: #d6b8d1;
  }

  .body .line:nth-child(even) {
    background-color: inherit;
  }
}

.purple-active-block {
  display: flex;
  margin-bottom: 120px;

  .content {
    flex: 1;
    margin-right: 30px;
  }

  .thumbnail {
    width: 536px;
    height: 517px;

    overflow: hidden;

    img{
      opacity: 0;
      width: 100%;
      height: 100%;
    }
  }

  .place-adress-contacts {
    margin-left: 0;
    max-width: 425px;
  }
}

.purple-active-block.reverse {
  flex-direction: row-reverse;
  .content {
    margin-right: 0;
    margin-left: 30px;
  }
}

/***************************************************************/

@media screen and (max-width: 1640px) {
  .purple-active-block .thumbnail {
    width: 356px;
    height: 356px;
  }
}

@media screen and (max-width: 991px) {
  .purple-map-block {
    flex-wrap: wrap;
    .content-container {
      min-width: 50%;
    }
  }

  .purple-active-block {
    flex-wrap: wrap;
    flex-direction: column-reverse !important;
    .thumbnail {
      margin-bottom: 30px;
    }
    .content {
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
  }
}

@media screen and (max-width: 767px) {

  .inner-page-table-purple {

    .body .line:nth-child(even) {
      background-color: #f1f1f1;
    }
  }

  .purple-map-block {
    .purple-map-container {
      display: none;
    }

    .content-container {
      min-width: 100%;
    }

    .purple-map-container.responsive {
      display: block;
    }
  }

  .purple-map-block .purple-map-container, .purple-map-block .thumbnail {
    width: 255px;
    margin: 0;
  }
}

@media screen and (max-width: 575px) {
  .purple-map-block .purple-map-container, .purple-map-block .thumbnail {
    width: 248px;
    margin: auto;
  }
}

@media screen and (max-width: 385px) {
  .purple-active-block .thumbnail {
    width: 290px;
    height: 290px;
  }
}