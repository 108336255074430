@import "../base/colors";
@import "../helpers/mixins";

a,
a:hover,
a:active,
a:focus,
a:visited {
  text-decoration: none;
}

.single-event-gallery {
  figure {
    overflow: hidden;
    position: relative;
  }

  figcaption {
    position: absolute;
    background: rgba(0, 0, 0, .6);
    width: 100%;
    height: 100%;
    top: 100%;
    left: 100%;
    text-align: center;
    padding-top: 30px;
    color: #fff;
    pointer-events: none;
    font-weight: 700;
    font-size: 0.9em;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1170px;
  }
}

/**/
.content-search-container {
  margin-bottom: 30px;

  input {
    border-radius: 8px;
    width: 100%;
    height: 41px;
    padding: 0 10px;
    border: 1px solid #000;
  }
}

* {
  outline: none;
}

a {
  @include transition(all, .4s);
}

body {
  background-color: #ffffff;
}

img {
  max-width: 100%;
  height: auto;
}

ul {
  margin: 0;
}

.breadcrumbs {
  padding-top: 18px;
  padding-bottom: 21px;
  a {

    color: $grey;
    display: inline-block;
    margin-right: 1px;
    &:after {
      content: '>';
      display: inline-block;
      padding-left: 4px;
    }
  }

  a:hover {
    color: #000;
  }

  a:last-child {
    &:after {
      display: none;
    }
  }
}

.section-title {
  background-color: #E1E1E1;
  font-weight: 600;
  font-size: 2.14em;
  padding-top: 12px;
  padding-bottom: 9px; 
  
  a{
    color: #000;
  }
}

.blue-section-title {
  background-color: $light-blue;
  color: #fff;
}

.purple-section-title {
  background-color: $purple;
  color: #fff;
}

.yellow-section-title {
  background-color: $yellow;
  color: #fff;
}

.hidden {
  display: none;
}

.no-padding {
  padding: 0;
}

/**/
.read-all {
  display: inline-block;
  width: 250px;
  height: 50px;
  text-align: center;
  line-height: 50px;
  font-size: 1.28em;
  border-radius: 20px;
  background-color: #1B74B6;
  color: #fff !important;
  border: 2px solid #1B74B6;
  margin-top: 20px;
  &:hover {
    color: $blue !important;
    background-color: #fff;
  }
}

.icon-floor {

  span {
    display: block;
    line-height: 1.1;
    font-weight: bold;
    font-style: normal;
  }

  .floor {
    font-size: 8px;
  }

  .num {
    font-size: 13px;
  }
}

.custom-icon {
  display: inline-block;
  background-repeat: no-repeat;
}

.pagination-icon-next,
.pagination-icon-prev {
  width: 9px;
  height: 8px;
}

.pagination-icon-prev {
  background-image: url('../img/prev-page.png');
}

.pagination-icon-next {
  background-image: url('../img/next-page.png');
}

.icon-download {
  width: 29px;
  height: 34px;
  background-image: url('../img/icon-download.png');
}

.icon-print {
  display: inline-block;
  background-image: url('../img/icon-print.png');
  background-repeat: no-repeat;
  width: 18px;
  height: 18px;
}

.icon-menu{
  width: 38px;
  height: 26px;
  background-image: url('../img/icon-menu.png');
}

.icon-mapmarker {
  background-image: url('../img/icon-mapmarker.png');
  width: 14px;
  height: 19px;
}

.icon-mapmarker-black {
  background-image: url('../img/icon-mapmarker-black.png');
  width: 14px;
  height: 19px;
}

.icon-mapmarker-blue {
  background-image: url('../img/icon-mapmarker-blue.png');
  width: 30px;
  height: 39px;
}

.icon-mobile {
  background-image: url('../img/icon-mobile.png');
  width: 11px;
  height: 17px;
}

.icon-mobile-black {
  background-image: url('../img/icon-mobile-black.png');
  width: 11px;
  height: 17px;
}

.bx-wrapper {
  background: transparent !important;
  border: 0 !important;
  box-shadow: none !important;
  margin: 0 !important;
}

.icon-mail {
  width: 13px;
  height: 13px;
  background-image: url('../img/icon-mail.png');
}

.icon-file {
  width: 20px;
  height: 25px;
  background-image: url('../img/icon-file.png');
}

.icon-mail-black {
  width: 13px;
  height: 13px;
  background-image: url('../img/icon-mail-black.png');
}

.icon-fax {
  width: 20px;
  height: 20px;
  background-image: url('../img/icon-fax.png');
}

.icon-world {
  width: 13px;
  height: 13px;
  background-image: url('../img/icon-world.png');
}

.icon-world-black {
  width: 13px;
  height: 13px;
  background-image: url('../img/icon-world-black.png');
}

.thumbnail {
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  background-size: cover;
  background-position: 50% 50%;
}

.fade {
  opacity: 0;
  @include transition(opacity, .15s);
}

.fade.visible {
  opacity: 1
}

#st-1 .st-btn[data-network='facebook'],
#st-1 .st-btn[data-network='twitter'],
#st-1 .st-btn[data-network='pinterest'],
#st-1 .st-btn[data-network='email'],
#st-1 .st-btn[data-network='sharethis'],
#st-1 .st-btn[data-network='sms'],
#st-1 .st-btn {
  background-color: #000 !important;
}

.border-image.float-right {
  float: right;
  padding-left: 15px;
  padding-bottom: 15px;
}

.title-ib {
  display: inline-block;
}

.border-image.float-left {
  float: left;
  padding-right: 15px;
  padding-bottom: 15px;
}

form.poll-form {
  margin: 20px 0;
  .form-check {
    margin-bottom: 10px;
  }

  button {
    cursor: pointer;
    padding: 0 10px;
    min-width: 150px;
    min-height: 44px;
    background-color: $blue;
    color: #fff;
    border: 1px solid $blue;
    @include transition(all, .4s);

    &:hover {
      background-color: #fff;
      color: $blue;
    }
  }
}

form.contact-form {
  margin: 20px 0;
  .form-group {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    border: 1px solid #ced4da;
    border-radius: .25rem;

    input, textarea {
      border: 0;
      width: calc(100% - 20px);
      outline: none !important;

      &:focus {
        border: 0 !important;
        box-shadow: none;
      }
    }
    .fa {
      min-width: 20px;
      margin: 0 5px;
    }
  }

  .field-wiadomosc {
    align-items: baseline;
  }

  .field-zgoda {
    flex-direction: row;
    border: 0;
    input {
      width: 40px;
    }

    label {
      width: calc(100% - 40px);
    }
  }

  input[type=submit] {
    background-color: $blue;
    border: 1px solid $blue;
    color: #fff;
    height: 44px;
    padding: 0 10px;
    min-width: 140px;

    @include transition(all, .5s);
    &:hover {
      background-color: #fff;
      color: $blue;
    }
  }
}

/* MEDIA ******************************/
@media screen and (min-width: 1640px) {
  .container {
    max-width: 1620px;
  }
}

@media screen and (min-width: 1640px) {
  .section-title {
    font-size: 1.9em;
  }
}

@media screen and (max-width: 600px) {
  .section-title {
    font-size: 1.8em;
    .row {
      margin: 0;
    }
  }

  .breadcrumbs {
    padding-left: 15px;
  }
}