
.index-menu-icons {
  padding: 90px 0;
  .container {
    padding: 0 100px;
  }
  .row {
    justify-content: space-between;
  }

  .index-menu-icon-block {
    width: 18%;
    margin-bottom: 60px;
    img {
      margin-bottom: 20px;
    }

    h3 {
      color: #000;
      font-size: 1.5em;
      font-weight: 600;
      text-transform: uppercase;
      line-height: 1.1;
    }

    .title {
      font-size: 1.5em;
    }
  }
}

@media screen and (max-width: 1640px) {
  .index-menu-icons {
    .container {
      padding: 0 15px;
    }
  }
}

@media screen and (max-width: 1199px) {
  .index-menu-icons {
    .row {
      justify-content: space-around;
    }

    .index-menu-icon-block {
      width: 30%;
    }
  }
}

@media screen and (max-width: 767px) {
  .index-menu-icons {
    padding: 60px 0;
    .index-menu-icon-block {
      width: 50%;
      text-align: center;
      padding: 0 15px;

      .title {
        font-size: 1.15em;
      }
    }
  }
}