$white: #ffffff;
$black: #000000;

$light-grey: #E1E1E1;
$grey: #707070;

$aqua: #26AFE4;
$purple: #7D166E;
$yellow: #f6cf32;
$orange: #FB883B;
$light-blue: #02A1E7;
$blue: #1B74B6;
$dark-blue: #01538F;
$footer-bg: #393434;


