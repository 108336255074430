//Global scoped classes
.no-gutters {
  margin-right: 0;
  margin-left: 0;

  > .col,
  > [class*="col-"] {
    padding-right: 0;
    padding-left: 0;
  }
}

//Variabls
@import "base/colors";
@import "base/body";

//Helpers
@import "helpers/mixins";

//Parts
@import "parts/main";
@import "parts/header";
@import "parts/footer";
@import "parts/categories";
@import "parts/new";
@import "parts/calendar";
@import "parts/datepicker";
@import "parts/index-menu-icons";
@import "parts/inner-page";
@import "parts/OM(O mieście)";
@import "parts/administracja";
@import "parts/blue-pages";
@import "parts/yellow-pages";
@import "parts/purple-pages";

@import "parts/newsletter";
@import "parts/christ";
@import "parts/contrast";