/* ======  CONTRAST  ====== */
body.contrast,
body.contrast .banner,
body.contrast .entertainment,
body.contrast .entertainment:after,
body.contrast .news,
body.contrast .news:after,
body.contrast .partners,
body.contrast .partners .logos,
body.contrast .section-title:before,
body.contrast .news article figure,
body.contrast .game-intro,
body.contrast .game-intro:after,
body.contrast .games-list,
body.contrast .pre-footer,
body.contrast .footer,
body.contrast .header .wcag-area .wcag,
body.contrast .header .wcag-area .login li a,
body.contrast .contact-form,
body.contrast .contact-form:after,
body.contrast .newsletter,
body.contrast .banner:after,
body.contrast .newsletter h3,
body.contrast .contact-form .wpcf7 input[type="text"],
body.contrast .contact-form .wpcf7 input[type="email"],
body.contrast .contact-form .wpcf7 textarea,
body.contrast .draw-intro,
body.contrast .draw-intro:after,
body.contrast .coloring,
body.contrast .coloring:after,
body.contrast .create-comic,
body.contrast .search-page,
body.contrast .media-intro,
body.contrast .media-intro:after,
body.contrast .movies,
body.contrast .animations,
body.contrast .animations article figure,
body.contrast .movies article figure,
body.contrast .movies:after,
body.contrast .profile,
body.contrast .widget_wysija_cont .updated,
body.contrast .widget_wysija_cont .login .message,
body.contrast .header .main-menu, .gray-bg {
  background: #000 !important;
}

body.contrast div,
body.contrast p,
body.contrast li,
body.contrast h1,
body.contrast h2,
body.contrast h3,
body.contrast h4,
body.contrast a,
body.contrast small,
body.contrast span,
body.contrast table td,
body.contrast table th,
body.contrast .contact-form .wpcf7 input[type="text"],
body.contrast .contact-form .wpcf7 input[type="email"],
body.contrast .contact-form .wpcf7 textarea,
body.contrast .coloring .carousel .nextButton,
body.contrast .coloring .carousel .prevButton,
body.contrast .button-area .btn {
  color: #ffd401 !important;
}

body.contrast .news article .news-body,
body.contrast .pre-footer .top,
body.contrast .header .main-menu li.current-menu-item a:after,
body.contrast .header .search-form,
body.contrast .header .search-form:before,
body.contrast .btn-orange,
body.contrast .contact-form .wpcf7 .send-contact,
body.contrast .newsletter .widget_wysija_cont .wysija-submit,
body.contrast .animations article .body-animation,
body.contrast .movies article .body-animation,
body.contrast .coloring .body-coloring,
body.contrast .button-area .btn.active {
  background-color: #ffd401 !important;
}

body.contrast .news article .news-body p,
body.contrast .news article .news-body h3,
body.contrast .news article .news-body a,
body.contrast .pre-footer .top,
body.contrast .header .search-form input[type="search"]::-webkit-input-placeholder,
body.contrast .btn-orange,
body.contrast .contact-form .wpcf7 .send-contact,
body.contrast .newsletter .widget_wysija_cont .wysija-submit,
body.contrast .animations article .body-animation h3,
body.contrast .movies article .body-animation h3,
body.contrast .animations article .body-animation p,
body.contrast .movies article .body-animation p,
body.contrast .animations article .body-animation .footer-animation .see-more,
body.contrast .movies article .body-animation .footer-animation .see-more,
body.contrast .coloring .body-coloring h3,
body.contrast .coloring .body-coloring p,
body.contrast .coloring .body-coloring a,
body.contrast .games-list .body-game h3,
body.contrast .games-list .body-game p,
body.contrast .games-list .body-game a,
body.contrast .button-area .btn.active {
  color: #000 !important;
}

body.contrast {

}

/**/

body.contrast .news article figure,
body.contrast .animations article,
body.contrast .movies article {
  box-shadow: none;
}

body.contrast .animations {
  padding-bottom: 90px;
}

body.contrast .pre-footer .top:before {
  border-bottom-color: #ffd401 !important;
}

body.contrast .header .search-form:after {
  border-right-color: #ffd401 !important;
}

body.contrast .header:after,
body.contrast .contact-form img,
body.contrast .create-comic:before,
body.contrast .entertainment .section-title:after,
body.contrast .entertainment .section-title:before,
body.contrast .draw-intro .text-center,
body.contrast .search-page .text-center,
body.contrast .media-intro .text-center,
body.contrast .game-intro .text-center {
  display: none;
}

body.contrast .contact-form .col-md-6,
body.contrast .draw-intro .col-md-6,
body.contrast .media-intro .col-md-6,
body.contrast .game-intro .col-md-6 {
  width: 100%;
  z-index: 999;
}

body.contrast .search-page .col-md-6 {
  margin-left: 25%;
}

body.contrast .header {
  border-bottom: 2px solid #ffd401;
  background: #000;
  .main-menu-submenu li {
    background-color: #000;
  }

  .main-menu-img .wrap {
    img {
      display: none;
    }
  }

  .bip-icon {
    display: none;
  }

  .header-right-top {
    .search {
      background-color: #ffd401;
      color: #000 !important;
      .fa {
        color: #000;
      }
    }

    div {
      span {
        color: #000 !important;
      }
    }

    .languages {
      .lang {
        color: #000 !important;
      }
    }
  }
}

body.contrast {
  .index-header-container {
    .slide {
      background-image: none !important;
    }
    .bx-pager {
      display: none;
    }
  }

  img {
    display: none !important;
  }

  .thumbnail {
    background-image: none !important;
    border: 1px solid #ffd401;
  }

  .calendar-container, .inner-header-container, .inner-page-section {
    background-image: none !important;

    .calendar-years-months:before {
      background-color: #ffd401;

    }
  }

  .icon-floor {
    .num, .floor {
      color: #000 !important;
    }
  }

  .pagination-icon-prev {
    background-image: url('../img/prev-page-yellow.png');
  }

  .pagination-icon-next {
    background-image: url('../img/next-page-yellow.png');
  }

  .inner-page-content-container {

    .two-columns .aqua-column, .three-columns.aqua {
      background: #000;
      border: 1px solid #ffd401;
    }

    .quote {
      &:before {
        background-image: url('../img/inner/quote-begin-yellow.png');
      }
      &:after {
        background-image: url('../img/inner/quote-end-yellow.png');
      }
    }
  }

  .icon-mobile-black {
    background-image: url('../img/icon-mobile-yellow.png');
  }

  .icon-world-black {
    background-image: url('../img/icon-world-yellow.png');
  }

  .icon-mapmarker-black {
    background-image: url('../img/icon-mapmarker-yellow.png');
  }

  .icon-mapmarker-blue {
    background-image: url('../img/icon-mapmarker-yellow-big.png');
  }

  .icon-mail-black {
    background-image: url('../img/icon-mail-yellow.png');
  }

  .icon-print {
    background-image: url('../img/icon-print-yellow.png');
  }

  .inner-page-content-container .page-title-with-img img {
    display: none;
  }

  .aktualnosc .image .date {
    background-color: #000;
    border: 1px solid #ffd401;
  }

  .yellow-single-details {
    background: #000;
    border: 1px solid #ffd401;
    .file {
      background: #000;
      border: 1px solid #ffd401;
      .icon-file {
        display: none;
      }
    }
  }
  .yellow-single-property {
    .content {
      .middle-title.yellow-block {
        color: #000 !important;
        background-color: #ffd401;
      }

      .lines {
        .line {
          .light-yellow-block {
            background: #000;
            border: 1px solid #ffd401;
          }

          .yellow-block {
            border-color: #ffd401;
            color: #000 !important;
            span {
              color: #000 !important;
            }
          }
        }
      }

      .bottom-block {
        border: 1px solid #ffd401;
      }
    }

    .img-links {
      .file {
        background: #000;
        border: 1px solid #ffd401;
        .icon-file {
          display: none;
        }
      }
      .tabs {
        a {
          background-color: #000;
          color: #ffd401;
        }
        a.active {
          color: #000 !important;
          background-color: #ffd401;
        }
      }
    }
  }

  .read-all {
    border: 1px solid #ffd401;
    background: #000;
    &:hover {
      background: #ffd401;
      color: #000 !important;
    }
  }

  .inner-page-section {
    .full-width-image {
      display: none;
    }

    .grey-block {
      background: #000;
      border: 1px solid #ffd401;
    }

    .blue-block {
      background: #ffd401;
      color: #000 !important;
    }

    .place-adress-contacts .contacts {
      background: #ffd401;
      color: #000 !important;

      .icon-mapmarker {
        background-image: url('../img/icon-mapmarker-black.png');
      }

      .icon-mobile {
        background-image: url('../img/icon-mobile-black.png');
      }

      .icon-fax {
        background-image: url('../img/icon-fax-black.png');
      }

      .icon-mail {
        background-image: url('../img/icon-mail-black.png');
      }

      .icon-world {
        background-image: url('../img/icon-world-black.png');
      }

      a, li {
        color: #000 !important;
      }
    }

    .full-width-image-with-text {
      display: block;
      border: 1px solid #ffd401;
      img {
        display: none;
      }
    }
  }

  .purple-map-block {
    background-color: #000;
    border: 1px solid #ffd401;
  }

  .inner-page-menu-container {

    ul.inner-page-menu,
    ul.inner-page-menu.blue-menu,
    ul.inner-page-menu.yellow-menu,
    ul.inner-page-menu.purple-menu {
      li.active a, li:hover > a {
        background: #ffd401 !important;
        color: #000 !important;

        .fa {
          color: #000 !important;
        }
      }

      a {
        color: #ffd401 !important;
        background: #000;
        border: 1px solid #ffd401;
        .fa {
          color: #ffd401 !important;
        }
      }

      a.active {
        background: #ffd401 !important;
        color: #000 !important;
        .fa {
          color: #000 !important;
        }
      }

      ul.submenu {

        a {
          color: #000 !important;
          background: rgba(255, 212, 1, .85) !important;
        }
        li.active a,
        li:hover > a {
          background: #ffd401 !important;
          color: #000 !important;
        }

        ul.submenu {
          a {
            color: #000 !important;
            background: rgba(255, 212, 1, .85) !important;
          }
          li.active a,
          li:hover > a {
            background: #ffd401 !important;
            color: #000 !important;
          }
        }
      }
    }
  }

  .calendar-events .calendar-event {
    hr {
      border: 1px solid #ffd401;
    }
    .content {
      background: #000;
      border: 1px solid #ffd401;
      &:before {
        background: none;
      }
    }
  }

  .mobile-menu-container {
    background: #000;
  }

  .news-section {
    .read-all {
      background: #ffd401 !important;
      color: #000 !important;
      border-color: #ffd401 !important;
    }

    div.date {
      background-color: #ffd401;
      color: #000;
      div {
        color: #000 !important;
      }
    }
  }

  #header-search-form-mobile {

    input, select {
      background: #000;
      border: 1px solid #ffd401;
      color: #ffd401;
      option {
        color: #ffd401;
      }
    }

    input[type=submit] {
      background: #ffd401;
      color: #000;
    }
  }

  .yellow-search-up a, .yellow-search-under a {
    background: #000;
    border: 1px solid #ffd401;
  }
  .yellow-search-up a.active, .yellow-search-under a.active {
    background-color: #ffd401;
    color: #000 !important;
  }

  .yellow-search-results {
    .result-block {
      .opis{
        background-color: #ffd401;
        color: #000 !important;
      }

      a.link{
        border-color: #ffd401;
        &:hover{
          background-color: #ffd401;
          color: #000 !important;
        }
      }
    }
  }

  .calendar-form-container, .calendar-footer, .index-menu-icons, .footer-top,
  .inner-page-content-container .form-container, .inner-page-table-container,
  .yellow-search-form-container {
    background: #000;
    border: 1px solid #ffd401;

    a.mapa {
      border: 1px solid #ffd401;
    }

    .placeholder {
      border: 1px solid #ffd401;
    }

    .newsletter-reg .textfield-container input {
      border: 1px solid #ffd401 !important;
    }

    img {
      display: none;
    }

    input, select {
      background: #000;
      border: 1px solid #ffd401;
      color: #ffd401;
      option {
        color: #ffd401;
      }
    }

    input[type=submit] {
      background: #ffd401;
      color: #000;
    }
  }

  .blue-place .adress-block a.read-all {
    background: #ffd401;
    color: #000 !important;
    border-color: #ffd401;
    &:hover {
      background: #ffd401;
      color: #000 !important;
      border-color: #ffd401;
    }
  }

  .percent-block .num:before {
    background-image: url('../img/inner/percent-yellow.png');
  }

  .files-download, .yellow-files-download {
    .line:nth-child(odd) {
      background: #000;
      border: 1px solid #ffd401;
    }
    .line {
      background-color: #000;
      .download {
        background-color: #000;
        color: #ffd401;
        .icon-download {
          background-image: url('../img/icon-download-yellow.png');
        }
        &:hover {
          background-color: #ffd401;
          color: #000 !important;
          border-color: #ffd401;
          .icon-download {
            background-image: url('../img/icon-download-black.png');
          }
        }
      }
    }
  }

  .inner-page-table-container {
    border: 0;

    .bottom-container {
      background-color: #000;
      border: 1px solid #ffd401;
    }

    .paginate_button {
      color: #ffd401 !important;

      &:hover {
        background: #ffd401 !important;
        color: #000 !important;
      }
    }

    .inner-page-table,
    .inner-page-table.blue,
    .inner-page-table.yellow,
    .inner-page-table.purple {

      .read {
        border: 1px solid #ffd401;
        &:hover {
          background-color: #ffd401;
          color: #000 !important;
          span {
            color: #000 !important;
          }
        }
      }

      .line {
        border: 1px solid #ffd401;
        background-color: #000 !important;
      }

      .head {
        background-color: #ffd401 !important;
        color: #000 !important;
        span {
          color: #000 !important;
        }

        .small {
          color: #000 !important;
        }
      }

      span.circle {
        border: 1px solid #000;
        background: inherit !important;
        .fa {
          color: #000 !important;
        }
      }

      span.num {
        background-color: #ffd401;
        color: #000 !important;
      }
    }
  }

  .inner-page-content-container {
    .index-menu-icons {
      border: 0;
    }
  }

  .section-title, .under-categories-section {
    background: #000;
    border: 1px solid #ffd401;
    .container {
      background: #000;
      border: 0;
    }
  }

  .header-search-container form {
    background: #000;
    border: 1px solid #ffd401;
    input {
      background: #000;
    }

    .close {
      color: #ffd401;
    }
  }

  .category-block {
    background: #000;
    border: 1px solid #ffd401;
    a .fa {
      color: #ffd401;
    }
  }

}

body.contrast .banner .col-md-offset-6 {
  margin-left: 25%;
}

body.contrast .btn-orange,
body.contrast .contact-form .wpcf7 .send-contact,
body.contrast .newsletter .widget_wysija_cont .wysija-submit,
body.contrast .button-area .btn.active {
  box-shadow: 3px 6px 0px #b39300;
}

body.contrast .news:after,
body.contrast .draw-intro:after,
body.contrast .entertainment:after,
body.contrast .media-intro:after,
body.contrast .movies:after,
body.contrast .game-intro:after,
body.contrast .coloring:after {
  border-bottom: 2px solid #ffd401;
}

body.contrast .contact-form .wpcf7 input[type="text"],
body.contrast .contact-form .wpcf7 input[type="email"],
body.contrast .contact-form .wpcf7 textarea,
body.contrast .button-area .btn {
  border-color: #ffd401;
}

body.contrast .media-intro h1 {
  margin-top: 100px;
}

body.contrast .create-comic {
  padding: 190px 0 100px 0;
}

/******** WPMU ***********/
#wpum-account, .wpum-login-form {
  width: 320px;
  margin: 0 auto;
}

#wpum-account-forms-tabs ul li a {
  color: #e2543e;
}

#wpum-account label {
  color: #e2543e;
}

.wpum-submit input[type="submit"] {
  background-color: #73b83f;
  color: #fff;
  margin-top: 20px;
  border: none;
  padding: 10px;
}

#wpum-account-forms-tabs ul li.active a {
  background-color: #73b83f;
  color: #fff;
}

body.contrast {

  .big-head-container {
    background-color: #000000 !important;
    background-image: none !important;
  }
  .header-navigation-right .up-section, .turquoise-section, .dark-blue-section {
    background-color: #000000 !important;
    border: 1px solid #ffd401 !important;
  }
  .info-section.dark-blue:before, .info-section.turquoise:before {
    background-color: transparent !important;
  }
  .news-section .arrow {
    background-color: #000000 !important;
    border: 1px solid #ffd401;
  }

  .turquoise-section.news-block {

  }
  .header-navigation-right .down-section .two-levels-menu .menu-item-has-children:hover, .header-navigation-right .down-section .two-levels-menu li ul {
    background-color: #000000 !important;
    border: 1px solid #ffd401;
    color: #ffd401 !important;

  }
  .header-navigation-right .down-section .two-levels-menu li ul a {
    color: #ffd401 !important;
  }
  .lighter-turquoise-section, .lighter-turquoise-hover:hover, .join-group-section:hover, .consultations-block:hover, .consultations-block:nth-child(2n) {
    background-color: #000000 !important;
  }
  .public-content .tabs-item.active, .header-navigation-right .down-section .two-levels-menu .current-menu-parent, .header-navigation-right .down-section .two-levels-menu .current-page-parent, .header-navigation-right .down-section .two-levels-menu .current_page_parent, .header-navigation-right .down-section .two-levels-menu > .current-menu-item, .see-documents, .register-doc-block {
    background-color: #000000 !important;
    border: 1px solid #ffd401 !important;
  }
  .public-content .tabs, .list-wytyczne-block, .aktual-news-block, .consultations-block .container, .consultations-block .row {
    border-bottom: 1px solid #ffd401 !important;
  }
  .submit-button {
    color: #000000 !important;
  }
  .aktual-news-block {
    border-top: none;
  }
  .pages-scroller-container .left .active {
    background-color: #000000 !important;
    border: 1px solid #ffd401 !important;
  }
  .pages-scroller-container {
    border-color: #ffd401 !important;
  }
  .contact-container {
    border: none !important;
  }
  .about-us-download .container {
    border-top: none !important;
    border-bottom: 1px solid #ffd401 !important;
  }
  .turquoise-button, .tags-links a {
    background-color: #000000 !important;
    border: 1px solid #ffd401 !important;
  }
  .join-group-container {
    border-top: 1px solid #ffd401 !important;
  }
}

/**/


