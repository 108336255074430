@import "../base/colors";
@import "../helpers/mixins";

.om-person-block {
  margin-bottom: 12s0px !important;

  .thumbnail {
    width: 139px;
    height: 139px;
  }

  .title {
    font-weight: 600;
    font-size: 1.85em;
  }

  .content {
    flex: 1;
    max-width: 75%;
    margin-left: 40px;
  }

}

.om-known-person-block {
  margin: 60px 0;
  display: flex;
  align-items: center;
  .up {
    margin-bottom: 45px;
  }

  p.main-paragraph {
    margin-bottom: 40px;
  }

  .content {
    flex: 1;
    margin-right: 30px;
  }

  span.name {
    font-weight: bold;
    font-size: 4.28em;
    line-height: 1.1;
    padding-right: 15px;
  }

  span.years-of-life {
    font-size: 2.92em;

  }
}

.om-known-person-block.reverse {
  flex-direction: row-reverse;

  .content {
    margin-right: 0;
    margin-left: 30px;
  }
}

.om-honor-person-block {
  margin-bottom: 90px;
  .up-part {
    display: flex;
    margin-bottom: 30px;
    .thumbnail {
      width: 356px;
      height: 356px;
      overflow: hidden;
      img {
        opacity: 0;
      }
    }

    .text {
      margin-right: 30px;
      margin-top: auto;
      flex: 1;
      .title {
        margin-bottom: 20px;
        max-width: 70%;
      }
      p {
        margin: 0;
      }
    }
  }
}

.om-honor-person-block.reverse {
  .up-part {
    flex-direction: row-reverse;

    .text {
      margin-right: 0;
      margin-left: 30px;
    }
  }
}

.om-places-block {
  margin-bottom: 90px;
  .title {
    margin-bottom: 40px;
  }

  .up-part {
    margin-bottom: 40px;
  }

  .bottom-part {
    display: flex;

    .left {
      flex: 1;
      margin-right: 30px;
    }

    .thumbnail {
      overflow: hidden;
      img {
        opacity: 0;
      }
    }
    .thumbnail {
      width: 603px;
      height: 623px;
      overflow: hidden;

      img {
        opacity: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
}

.om-places-block.reverse {

  .bottom-part {
    flex-direction: row-reverse;

    .left {
      margin-right: 0;
      margin-left: 30px;
    }
  }
}

/*MEDIA ***********************************************************/
@media screen and (max-width: 1630px) {
  .om-honor-person-block .up-part .thumbnail {
    width: 305px;
    height: 305px;
  }

  .om-places-block .bottom-part .thumbnail {
    width: 403px;
    height: 423px;
  }
}

@media screen and (max-width: 1199px) {
  .om-known-person-block {
    .content {
      flex: 1 1 90%;
    }
  }
}

@media screen and (max-width: 991px) {
  .om-known-person-block {
    span.name {
      font-size: 3em;
    }

    span.years-of-life {
      font-size: 2em;
    }
  }

  .om-places-block .bottom-part {
    flex-wrap: wrap;
    flex-direction: column-reverse !important;
    .left {
      margin: 30px 0 0 !important;
      flex: 1 1 100%;
    }
  }
}

@media screen and (max-width: 767px) {
  .om-person-block .content {
    margin-left: 0;
    max-width: 100%;
    margin-top: 15px;
    flex: 1 1 100%;
  }

  .om-known-person-block {
    flex-wrap: wrap;
    flex-direction: column-reverse;
    .content {
      margin: 0;
      max-width: 100%;
      flex: 1 1 100%;
    }
  }

  .om-known-person-block.reverse {
    flex-direction: column-reverse;
    .content {
      margin: 0;
    }
  }

  .om-honor-person-block .up-part {
    flex-wrap: wrap;
    flex-direction: column-reverse;
    .title {
      margin-top: 15px;
    }
    .text {
      margin: 0 !important;
    }
    .thumbnail {
      width: 290px;
      height: 290px;
    }
  }

  .om-honor-person-block.reverse .up-part {
    flex-direction: column-reverse;
  }
}

@media screen and (max-width: 375px) {
  .om-known-person-block span.name {
    font-size: 2em;
  }

  .om-known-person-block span.years-of-life {
    font-size: 1.6em;
  }

  .om-places-block .bottom-part .thumbnail {
    width: 290px;
    height: 320px;
  }
}