
body.christmass {

  .calendar-container {
    background-image: url('../img/calendar/bg-christ.png') !important;
  }

  .footer-top {
    background-image: url('../img/footer-bg-christ.png') !important;
    background-repeat: no-repeat;
    background-size: contain;
  }
}