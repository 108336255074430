@mixin cover-background($img-uri, $background-top:"center", $background-left:"center", $background-attachment:"fixed") {
  background: url($img-uri) no-repeat unquote($background-top) unquote($background-left) unquote($background-attachment);
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

@mixin box-shadow($top, $left, $blur, $color, $inset: false) {
  @if $inset {
    -webkit-box-shadow:inset $top $left $blur $color;
    -moz-box-shadow:inset $top $left $blur $color;
    box-shadow:inset $top $left $blur $color;
  } @else {
    -webkit-box-shadow: $top $left $blur $color;
    -moz-box-shadow: $top $left $blur $color;
    box-shadow: $top $left $blur $color;
  }
}
@mixin text-shadow($x: 2px, $y: 2px, $blur: 4px, $color: rgba(0,0,0,.2)) {
  text-shadow: $x $y $blur $color;
}
@mixin border-radius($radius){
  -moz-border-radius: $radius !important;
  -webkit-border-radius: $radius !important;
  border-radius: $radius !important;
}

@mixin opacity($opacity) {
  opacity: $opacity;
  $opacity-ie: $opacity * 100;
  filter: alpha(opacity=$opacity-ie); //IE8
}
@mixin center-block {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
@mixin background-gradient($startColor: #3C3C3C, $endColor: #999999) {
  background-color: $startColor;
  background-image: -webkit-gradient(linear, left top, left bottom, from($startColor), to($endColor));
  background-image: -webkit-linear-gradient(top, $startColor, $endColor);
  background-image:    -moz-linear-gradient(top, $startColor, $endColor);
  background-image:     -ms-linear-gradient(top, $startColor, $endColor);
  background-image:      -o-linear-gradient(top, $startColor, $endColor);
  background-image:         linear-gradient(top, $startColor, $endColor);
  filter:            progid:DXImageTransform.Microsoft.gradient(startColorStr='#{$startColor}', endColorStr='#{$endColor}');
}
@mixin background-horizontal($startColor: #3C3C3C, $endColor: #999999) {
  background-color: $startColor;
  background-image: -webkit-gradient(linear, left top, right top, from($startColor), to($endColor));
  background-image: -webkit-linear-gradient(left, $startColor, $endColor);
  background-image:    -moz-linear-gradient(left, $startColor, $endColor);
  background-image:     -ms-linear-gradient(left, $startColor, $endColor);
  background-image:      -o-linear-gradient(left, $startColor, $endColor);
  background-image:         linear-gradient(left, $startColor, $endColor);
  filter:            progid:DXImageTransform.Microsoft.gradient(startColorStr='#{$startColor}', endColorStr='#{$endColor}', gradientType='1');
}
@mixin transition($transition, $time){
  -moz-transition: $transition $time;
  -webkit-transition: $transition $time;
  -o-transition: $transition $time;
  transition: $transition $time;
}
@mixin transition-effect($transition, $effect, $time){
  -moz-transition: $transition $effect $time;
  -webkit-transition: $transition $effect $time;
  -o-transition: $transition $effect $time;
  transition: $transition $effect $time;
}